var {
  &.empty {
    color: $gray-300;

    &::after {
      content: "— empty —";
    }
  }

  &.none {
    color: $gray-300;

    &::after {
      content: "— none —";
    }
  }
}

pre {
  &.pre-transcript {
    padding: 1.5rem .75rem;
    font-size: $font-size-xs;
    line-height: 1.5rem;
    background: linear-gradient(
      to bottom,
      rgb(0 0 0 / 5%),
      rgb(0 0 0 / 5%) 50%,
      white 50%,
      white
    );
    background-size: 100% 3rem;
    background-attachment: local;
    background-position: 0 1.5rem;
    background-origin: content-box;
    box-shadow: inset 0 0 .25rem 0 rgb(0 0 0 / 20%);
  }
}
