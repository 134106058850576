a {
  &.card {
    color: $gray-700;

    &.transition {
      transition: background-color 200ms ease,
                  border-color 200ms ease,
                  color 200ms ease,
                  box-shadow 100ms ease,
                  transform 100ms ease;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: $link-color !important;
      border-color: $link-color !important;
      color: $white !important;
      text-decoration: none !important;
      box-shadow: $box-shadow !important;

      &.transition {
        transform: translateY(-4px);
      }

      .card-header,
      .card-footer {
        background-color: $link-color !important;
        color: $white !important;
      }

      .card-header{
        border-bottom-color: rgb(255 255 255 / 12.5%);
      }

      .card-footer {
        border-top-color: rgb(255 255 255 / 12.5%);
      }

      .badge {
        background-color: $white !important;
        border-color: transparent !important;
        color: $link-color !important;

        &.badge-outline {
          background-color: transparent !important;
          border-color: $white !important;
          color: $white !important;
        }

        i.iconic {
          color: $link-color !important;
        }
      }

      i.iconic {
        color: $white !important;
      }

      .text-danger,
      .text-muted,
      .text-success,
      .text-warning {
        color: $white !important;
      }
    }
  }

  &.active {
    .card {
      background-color: $link-hover-color;
      box-shadow: 0 0 16px 0 rgb(0 0 0 / 20%);
      color: white;
    }
  }

  &:hover {
    .card {
      background-color: $link-color;
      box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
      color: white;
    }
  }

  .card {
    color: $body-color;

    hr {
      border-top-color: rgb(255 255 255 / 10%);
    }
  }
}

.card {
  margin-bottom: 0;
}

.card-sm {
  .card-block {
    padding: 1rem .75rem;
    font-size: $font-size-sm;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding: .5rem;
    }
  }
}

.card-lg {
  .card-block {
    padding: 3rem 2rem;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding: .75rem;
    }
  }
}

.card-shadow {
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 10%);
}

.card-shadow-sm {
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
}

.card-borderless {
  border: none;

  .card-header,
  .card-footer {
    border: none;
  }
}

.card-noradius {
  border-radius: 0;
}

.card-transparent {
  background-color: transparent;

  .card-header,
  .card-footer {
    background-color: transparent;
    border: none;
  }
}

.card-deck {
  .card {
    flex: 0 0 31%;
    margin-bottom: 1.25rem;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex: 0 0 47%;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      flex: 0 0 100%;
    }
  }
}

.has-parent {
  &::before {
    display: block;
    position: absolute;
    content: "";
    top: calc(-2rem + 2px);
    left: 50%;
    height: calc(2rem - 2px);
    border-left: 2px dotted $gray-lighter;
  }
}

.is-sibling-before {
  &::before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: calc(2rem - 2px);
    margin-left: calc(-2rem + 2px);
    border-top: 2px dotted $gray-light;
  }
}

.has-sibling-before {
  &::after {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    width: calc(2rem - 2px);
    margin-right: calc(-2rem + 2px);
    border-top: 2px solid $gray-light;
  }
}

.is-sibling-after {
  &::before {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: calc(2rem - 2px);
    margin-left: calc(-2rem + 2px);
    border-top: 2px solid $gray-light;
  }
}

.has-sibling-after {
  &::after {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    width: calc(2rem - 2px);
    margin-right: calc(-2rem + 2px);
    border-top: 2px dotted $gray-lighter;
  }
}

.has-child,
.has-children {
  &::after {
    display: block;
    position: absolute;
    content: "";
    left: 50%;
    height: calc(2rem - 2px);
    border-left: 2px solid $gray-light;
  }
}

.has-children {
  margin-bottom: 1rem;

  &::before {
    display: block;
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: calc(2rem - 2px);
    margin-bottom: -1.25rem;
    border-bottom: 2px solid $gray-light;
  }

  &::after {
    height: 1.25rem;
  }
}