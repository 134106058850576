fieldset {
  &.box {
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: $border-radius-lg;

    legend {
      width: auto;
      max-width: auto;
      font-size: $font-size-base;

      &.text-muted {
        color: $gray-400 !important;
      }
    }

    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  fieldset {
    &.box {
      border: 0;

      legend {
        font-size: $font-size-lg;
      }
    }
  }
}
