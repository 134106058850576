// Fix the left padding issue added by the new BS v4.2.1 switch class
.custom-switch {
  padding-left: 0;
}

.custom-switch-margin {
  margin-top: .85em;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: $success;
  border-color: $success;
}
