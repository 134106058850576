html {
  &.signin,
  &.error {
    svg {
      &.logo {
        width: 160px;
        height: 60px;

        * {
          fill: $brand;
          stroke: transparent;
        }
      }

      &.icon {
        width: 96px;
        height: 96px;

        @media (max-width: map-get($grid-breakpoints, md)) {
          width: 64px;
          height: 64px;
        }

        * {
          fill: rgb(255 255 255 / 100%);
          stroke: transparent;
        }
      }
    }
  }

  &.signin {
    .branding {
      .display-4 {
        @media (max-width: map-get($grid-breakpoints, md)) {
          font-size: $h1-font-size;
        }
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      .animation {
        animation-duration: 0ms;
      }
    }

    .footer {
      position: inherit;
      bottom: inherit;
      width: auto;
    }
  }

  &.error {
    body {
      background-color: $brand-danger;
      color: white;
    }
  }
}
