.badge-outline {
  background-color: transparent;

  @each $color, $value in $theme-colors {
    &.badge-#{$color} {
      border: 1px solid $value;
      color: $value;
    }
  }
}

.badge-sm {
  padding: 0.1875em 0.25em;
  font-size: 66.67%;
  font-weight: normal;
}

.badge-white {
  background-color: white;
  color: black;
}

.badge-linkedin {
  background-color: $color-linkedin;
  color: white;
}

sup {
  &.badge {
    padding: .25em .5em;
    font-size: 66.67%;
  }
}
