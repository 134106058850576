.bg-background {
  background-color: $color-background;
}

.bg-brand {
  background-color: $brand;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-gray-400 {
  background-color: $gray-400;
}

.bg-gray-500 {
  background-color: $gray-500;
}

.bg-gray-600 {
  background-color: $gray-600;
}

.bg-gray-700 {
  background-color: $gray-700;
}

.bg-gray-800 {
  background-color: $gray-800;
}

.bg-gray-900 {
  background-color: $gray-900;
}

.bg-selfservice {
  background-color: $brand-selfservice;
}

.bg-payroll {
  background-color: $brand-payroll;
}

.bg-talent {
  background-color: $brand-talent;
}

.bg-productivity {
  background-color: $brand-productivity;
}

.bg-analytics {
  background-color: $brand-analytics;
}

.bg-recruit {
  background-color: $brand-recruit;
}

.bg-connect {
  background-color: $brand-connect;
}

.bg-action {
  background-color: $color-action;
}

.bg-applicants {
  background-color: $color-applicants;
}

.bg-filter {
  background-color: $color-filter;
}

.bg-more {
  background-color: $color-more;
}

.bg-stages {
  background-color: $color-stages;
}

.bg-light {
  &.recruit {
    background-color: rgb(228 230 231 / 60%) !important;
  }
}

.bg-hover-primary {
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: white;

    * {
      color: white;
    }
  }
}

.bg-hover-danger {
  &:hover {
    background-color: $brand-danger;
    border-color: $brand-danger;
    color: white;

    * {
      color: white;
    }
  }
}

.bg-striped-135 {
  background: repeating-linear-gradient(135deg, rgb(255 255 255 / 0%), rgb(255 255 255 / 0%) .5rem, rgb(0 0 0 / 7.5%) .5rem, rgb(0 0 0 / 7.5%) 1rem);
}

.bg-placeholder {
  background-color: $gray-200;
}
