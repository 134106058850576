.modal-nav-mobile {
  .modal-dialog {
    overflow-y: initial !important;
    left: 0;
    margin: 0;
    background-color: $brand-primary;
    box-shadow: 4px 0 8px 0 rgb(0 0 0 / 40%);
  }

  .modal-content {
    background-color: $brand-primary;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .modal-header {
    height: $navbar-height-mobile;
    padding: .5rem .75rem;
    background-color: rgba($brand-primary-dark, .90);
    border-radius: 0;
    z-index: 1000;

    img {
      &.logo {
        height: ($navbar-height-mobile * 0.5);
        margin-top: .25rem;
      }
    }
  }

  .modal-body {
    overflow-y: scroll;
    height: calc(100%);
    margin-top: -40px;
    padding-top: 60px;
    color: white;
  }

  .close {
    margin: 0;
    margin-top: -.25rem;
    padding: 0;
    font-size: 2rem;
    font-weight: 300;
    text-shadow: none;
    color: white;
  }

  &.left .modal-dialog,
  &.right .modal-dialog {
    width: auto;
    height: 100%;
    transform: translate3d(0%, 0, 0);
  }

  &.left.fade {
    .modal-dialog {
      left: -320px;
      transition: left 300ms ease-out;
    }

    &.show .modal-dialog {
      left: 0;
    }
  }

  &.right.fade {
    .modal-dialog {
      right: -320px;
      transition: right 300ms ease-out;
    }

    &.show .modal-dialog {
      right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    &.left .modal-dialog,
    &.right .modal-dialog {
      width: 320px;
    }
  }

  @include media-breakpoint-up(sm) {
    &.left .modal-dialog,
    &.right .modal-dialog {
      width: auto;
    }
  }

  .nav-pills {
    .nav-item {
      border-bottom: 1px solid rgb(255 255 255 / 20%);
    }

    .nav-link {
      background-color: transparent;
      color: rgb(255 255 255 / 60%);

      &.active {
        color: white;

        &::before {
          display: inline-block;
          top: .8rem;
          left: 1.325rem;
          margin-left: -1rem;
          margin-right: .25rem;
          line-height: 1;
          vertical-align: baseline;
          content: '\e153';
          font-family: iconic-sm, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: .75rem;
          text-align: center;
          box-sizing: content-box;
        }
      }
    }
  }
}

.modal-fullscreen {
  z-index: 2500;

  .close {
    font-size: 3rem;
  }

  @include media-breakpoint-up(xs) {
    .modal-dialog {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin: 0;
    }

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
      overflow: auto;
    }

    .modal-header {
      padding: .5rem 1rem;
      border-bottom: 0;
      background-color: transparent;
      color: $body-color;
    }

    .modal-title {
      padding-top: .5rem;
      font-size: $font-size-lg;
    }
  }
}


.modal-timesheet {
  .modal-header {
    background-color: $brand-primary-dark;
    color: rgb(255 255 255 / 80%);
  }
}

.modal-header {
  min-height: 60px;
  overflow-x: hidden !important;
}


@media screen and (width >= 990px) {
    .modal-container {
      padding-bottom: 50px
    }

    .modal-centre {
      border-radius: .25rem;
      border-top: 1px solid rgb(0 0 0 / 10%);
      border-right: 1px solid rgb(0 0 0 / 10%);
      border-left: 1px solid rgb(0 0 0 / 10%);
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 7.5%) !important;
      max-width: 1140px;
    }

    #modal-content {
      padding: 2rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
}