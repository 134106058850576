input {
  &[type="search"] {
    &::-webkit-search-cancel-button {
      appearance: none;
      width: 1.25rem;
      height: 1.25rem;
      background: transparent svg-uri("<svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'><path fill='#{$forms-search-cancel-button-color}' d='#{$forms-search-cancel-button-svg}'/></svg>") no-repeat 0 0;
    }
  }
}

.form-control-icon {
  &[disabled],
  &[readonly] {
    padding-right: ($input-padding-x * 3);
    background-repeat: no-repeat;
    background-position: center right calc($input-height * 0.25);
    background-size: calc($input-height * 0.5) calc($input-height * 0.5);
    background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-disabled-color}' d='#{$forms-control-disabled-svg}'/></svg>");
  }
}

.form-control-xs {
  padding: .2rem .5rem;
  font-size: $font-size-xs;
  line-height: 1.25;
  border-radius: .125rem;
}

.form-control-lg {
  &.form-control-success,
  &.form-control-warning,
  &.form-control-danger {
    background-position: center right .75rem;
    background-size: 1.5rem 1.5rem;
  }
}

.form-control-xl {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $h3-font-size;

  @include border-radius($input-border-radius-lg);
}

.form-control-icon-calendar,
.form-control-icon-time,
.form-control-icon-search,
.form-control-icon-lock {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .365rem;
  background-size: 1.5rem 1.5rem;
}

.form-control-icon-calendar {
  background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-icon-calendar-color}' d='#{$forms-control-icon-calendar-svg}'/></svg>");

  &.form-control-danger {
    background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-icon-danger-color}' d='#{$forms-control-icon-calendar-svg}'/></svg>");
  }
}

.form-control-icon-time {
  background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-icon-time-color}' d='#{$forms-control-icon-time-svg}'/></svg>");

  &.form-control-danger {
    background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-icon-danger-color}' d='#{$forms-control-icon-time-svg}'/></svg>");
  }
}

.form-control-icon-search {
  background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-icon-search-color}' d='#{$forms-control-icon-search-svg}'/></svg>");

  &.form-control-danger {
    background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-icon-danger-color}' d='#{$forms-control-icon-search-svg}'/></svg>");
  }
}

.form-control-icon-lock {
  background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-icon-lock-color}' d='#{$forms-control-icon-lock-svg}'/></svg>");
}

.form-control-monospace {
  font-family: $forms-family-monospace;
  letter-spacing: .125rem;
}

.form-control-monospace-focus {
  &:focus {
    font-family: $forms-family-monospace;
    letter-spacing: .125rem;
  }
}

.form-group-floating-label {
  position: relative;

  label {
    display: block;
    position: relative;
    top: 2rem;
    opacity: 0;
    z-index: 0;
    height: 0;
    transition: all 300ms ease;
  }

  input,
  textarea {
    position: relative;
    z-index: 1;
  }
}

.form-group-floating-label-with-value {
  label {
    top: 0;
    height: 1.5rem;
    opacity: 1;
  }
}

.form-control-select:not([multiple]) {
  display: block;
  width: 100%;
  max-width: 100%;
  height: $input-height-inner;
  margin: 0;
  padding: .375rem 2rem .375rem .75rem;
  background-color: white;
  background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$forms-control-svg-color}' d='#{$forms-control-svg-down}'/></svg>");
  background-repeat: no-repeat, repeat;
  background-position: right .25rem top .125rem, 2rem 2rem;
  background-size: auto, 100%;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  appearance: none;
  font-family: $font-family-base;
  font-weight: normal;
  line-height: 1.3;
  color: $gray-700;

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: 0 0 0 .2rem rgb(0 123 255 / 25%);
    box-shadow: 0 0 0 .2rem -moz-mac-focusring;
    color: $gray-700;
    outline: none;
  }

  option {
    font-weight: normal;
  }
}
