.btn-xs {
  padding: ($btn-padding-y-sm * 0.5) ($btn-padding-x-sm - .25);
  font-size: $font-size-xs;
  line-height: ($line-height-sm - .25);
  border-radius: $btn-border-radius-xs;
}

.btn-white {
  @include button-variant(white, white, #ccc);
}

.btn-outline-white {
  @include button-outline-variant(white);

  &:hover {
    color: black;
  }
}

.btn-pill {
  border-radius: 10rem;
}

.btn-switch-on {
  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-success-bg !important;
    color: white !important;
  }
}

.btn-switch-pause {
  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-warning-bg !important;
    color: white !important;
  }
}

.btn-switch-off {
  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-danger-bg !important;
    color: white !important;
  }
}

.btn-outline-switch-on {
  &.active,
  &:active,
  &:focus,
  &:hover {
    @include button-outline-variant($btn-success-bg);
  }
}

.btn-outline-switch-pause {
  &.active,
  &:active,
  &:focus,
  &:hover {
    @include button-outline-variant($btn-warning-bg);
  }
}

.btn-outline-switch-off {
  &.active,
  &:active,
  &:focus,
  &:hover {
    @include button-outline-variant($btn-danger-bg);
  }
}
