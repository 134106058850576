@import "variables";

.placeholder {
  position: relative;
  overflow: hidden;
  background-color: $placeholder-bg;

  &::before {
    content: " ";
    position: absolute;
    inset: 0 0 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: placeholder_animation $placeholder-animation-duration linear infinite;
    background: linear-gradient(to right, rgba($placeholder-bg, 0) 47%, rgba($placeholder-bg, .75) 50%, rgba($placeholder-bg, 0) 53%) 50% 50%;
  }

  .placeholder-text {
    height: .5rem;
    border-radius: $placeholder-radius;
    background-color: $placeholder-color;
  }

  ins {
    &.placeholder-text {
      text-decoration: none;
    }
  }

  .placeholder-text-sm {
    height: .25rem;
  }

  .placeholder-text-lg {
    height: 1rem;
  }

  .placeholder-avatar {
    width: $placeholder-avatar-size;
    height: $placeholder-avatar-size;
    background-color: $placeholder-color;
  }
}

@keyframes placeholder_animation {
  0% {
    transform: translate3d(-25%, 0, 0);
  }

  100% {
    transform: translate3d(25%, 0, 0);
  }
}

.bg-applicants {
  .placeholder {
    background-color: $color-applicants;

    &::before {
      background: linear-gradient(to right, rgba($color-applicants, 0) 47%, rgba($color-applicants, .75) 50%, rgba($color-applicants, 0) 53%) 50% 50%;
    }
  }
}
