@use "sass:color";

// Aurion
// --------------------------------------------------

$brand                                      : #387bbd;                           // rgb(56,123,189)
$brand-selfservice                          : #5fc5bf;
$brand-payroll                              : #00ac89;
$brand-talent                               : #953c92;
$brand-productivity                         : #ffda02;
$brand-analytics                            : #00b5d1;
$brand-recruit                              : #eca55d;
$brand-connect                              : #e44398;


// Bootstrap
// --------------------------------------------------

$black                                      : black;
$white                                      : white;

$blue                                       : $brand;
$indigo                                     : #6610f2;
$purple                                     : $brand-talent;
$pink                                       : $brand-connect;
$red                                        : #bd2c00;
$orange                                     : #fd7e14;
$yellow                                     : #f93;
$green                                      : #6cc644;
$teal                                       : $brand-payroll;
$cyan                                       : #5bc0de;

$gray-500                                   : #7a8187;                           // rgb(122,129,135)
$gray-100                                   : color.mix($gray-500, white, 20%);
$gray-200                                   : color.mix($gray-500, white, 40%);
$gray-300                                   : color.mix($gray-500, white, 60%);
$gray-400                                   : color.mix($gray-500, white, 80%);
$gray-600                                   : color.mix($gray-500, black, 80%);
$gray-700                                   : color.mix($gray-500, black, 60%);
$gray-800                                   : color.mix($gray-500, black, 40%);
$gray-900                                   : color.mix($gray-500, black, 20%);

$primary                                    : $blue;
$secondary                                  : $gray-500;
$success                                    : $green;
$info                                       : $cyan;
$warning                                    : $yellow;
$danger                                     : $red;
$light                                      : rgb(255 255 255 / 80%);
$dark                                       : rgb(0 0 0 / 60%);

$brand-primary                              : $blue;
$brand-primary-darkest                      : color.mix($brand-primary, black, 25%);   // #0e1f2f
$brand-primary-darker                       : color.mix($brand-primary, black, 50%);   // #1c3e5f
$brand-primary-dark                         : color.mix($brand-primary, black, 75%);   // #2a5c8e
$brand-primary-light                        : color.mix($brand-primary, white, 75%);   // #6a9cce
$brand-primary-lighter                      : color.mix($brand-primary, white, 50%);   // #9cbdde
$brand-primary-lightest                     : color.mix($brand-primary, white, 25%);   // #d9e6f2

$brand-success                              : $green;
$brand-info                                 : $cyan;
$brand-warning                              : $yellow;
$brand-danger                               : $red;

$gray                                       : $gray-500;
$gray-darkest                               : $gray-900;
$gray-darker                                : $gray-800;
$gray-dark                                  : $gray-700;
$gray-light                                 : $gray-300;
$gray-lighter                               : $gray-200;
$gray-lighting                              : $gray-lighter;
$gray-lightest                              : $gray-100;

$brand-inverse                              : $gray-dark;


// Foundation
// --------------------------------------------------

$primary-color                              : $brand-primary;
$secondary-color                            : $gray;
$success-color                              : $brand-success;
$warning-color                              : $brand-warning;
$alert-color                                : $brand-danger;
$light-gray                                 : $gray-lightest;
$medium-gray                                : $gray-light;
$dark-gray                                  : $gray-dark;
$pre-color                                  : $gray-dark;


// Shared
// --------------------------------------------------

$today-bg                                   : $brand-warning;
$card-cap-bg                                : $white;
$color-action                               : color.mix($primary, black, 75%);
$color-applicants                           : $brand-talent;
$color-filter                               : $warning;
$color-more                                 : $gray-700;
$color-stages                               : $gray-700;
$color-background                           : #DEE0E1;


// Social
// --------------------------------------------------

$color-twitter                              : #00aced;
$color-facebook                             : #3b5998;
$color-googleplus                           : #dd4b39;
$color-youtube                              : #b00;
$color-linkedin                             : #007bb6;
$color-instagram                            : #517fa4;
$color-pinterest                            : #cb2027;
$color-vimeo                                : #aad450;


