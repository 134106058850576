@import "variables";
@import 'component-custom-switch';

.custom-switch {
  &.required {
    margin-left: -.75rem;
    padding-left: .5rem;
    border-left: .25rem solid $brand-danger;
  }
}

.custom-switch .custom-switch-input {
  display: inline-block;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
