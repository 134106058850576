// By default Bootstrap changes text to black to meet colour contrast.
.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning,
.btn-info {
  color: white;

  &:hover,
  &:disabled,
  &:active,
  &:focus {
    color: white;
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  &:checked,
  &:hover,
  &.active {
    color: white;
  }
}

// Leave balances day/hours/week selectors
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.dropdown-toggle.show {
  color: white;
}

.btn-check+.btn-outline-secondary {
  &.active,
  &:active {
    color: white;
  }
}

// Timekeeper Cog Requires Special Treatment
#btnSetFavouriteTab{
  border: none;
}