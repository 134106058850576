.form-group-lg {
  margin-bottom: 2rem;

  label {
    &.col-form-label {
      padding: 0;
      font-size: $font-size-lg;
      font-weight: 500;
      line-height: 1.2;
      text-transform: inherit;
      color: inherit;
    }
  }

  .form-text {
    margin-top: 0;
    font-size: $font-size-sm;
    line-height: 1rem;
  }
}
