.btn-xs,
.btn-group-xs > .btn {
  padding: 0.125rem 0.375rem;
}

.btn-black {
  @include button-variant(black, black, #333);
}

.btn-action {
  @include button-variant(
    $color-action,
    $color-action,
    mix($color-action, black, 80%)
  );
}

.btn-applicants {
  @include button-variant(
    $color-applicants,
    $color-applicants,
    mix($color-applicants, black, 80%)
  );
}

.btn-filter {
  @include button-variant(
    $color-filter,
    $color-filter,
    mix($color-filter, black, 80%)
  );
}

.btn-more {
  @include button-variant(
    $color-more,
    $color-more,
    mix($color-more, black, 80%)
  );
}

.btn-stages {
  @include button-variant(
    $color-stages,
    $color-stages,
    mix($color-stages, black, 80%)
  );
}

.btn-outline-black {
  @include button-outline-variant(black);

  &:hover {
    color: white;
  }
}

.btn-pill {
  &.btn-icon {
    padding: $btn-padding-y 0.625rem;

    &.btn-lg {
      padding: $btn-padding-y 0.6875rem;
    }
  }
}

.btn-arrow {
  &::after {
    position: absolute;
    display: inline-block;
    right: 2rem;
    margin-top: 2.25%;
    line-height: 1;
    font-size: 150%;
    text-decoration: none;
    transform: translateY(-50%);
    opacity: 0.5;
    content: "»";
  }

  span {
    &.pull-right {
      margin-right: 2rem;
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 5rem;
    text-align: right;
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
}

.responsive {
  &.d-flex {
    .btn {
      margin-right: 0.125rem;
      margin-left: 0.125rem;
    }

    > *:first-child {
      margin-left: 0;
    }

    > *:last-child {
      margin-right: 0;
    }

    select {
      margin-left: 0.125rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .responsive {
    &.d-flex {
      .btn {
        margin: 0.125rem 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .btn-responsive {
    display: block;
    width: 100%;
  }
}
