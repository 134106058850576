.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.h-md-50 {
  @media (min-width: map-get($grid-breakpoints, md)) {
    height: 50% !important;
  }
}

.h-lg-100 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    height: 100% !important;
  }
}

.w-400px {
  width: 400px;
}

.mh-80px {
  min-height: 80px;
}