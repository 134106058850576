$dropzone-primary-color:          $primary;
$dropzone-success-color:          $success;
$dropzone-error-color:            $danger;

// Sizes
$dropzone-font-size-sm:           $font-size-sm;
$dropzone-font-size-xs:           $font-size-sm - .125;

// Radii
$dropzone-border-radius:          $border-radius;
$dropzone-border-radius-sm:       $border-radius-sm;
$dropzone-border-radius-lg:       $border-radius-lg;

// Icons
$dropzone-svg-icon:               "M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z";
$dropzone-svg-icon-success:       "M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM10 17l-3.5-3.5 1.41-1.41L10 14.17 15.18 9l1.41 1.41L10 17z";
$dropzone-svg-icon-error:         "M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h.71C7.37 7.69 9.48 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3s-1.34 3-3 3z";
$dropzone-svg-icon-size:          2rem;
