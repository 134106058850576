/* We use custom file input fields but same class names. */
div.form-control {
    height: 2.4rem;
}

div.form-control:not([readonly]) {
    background: transparent;
}

/* Bug: Some file inputs appear disabled despite not being so. */
.form-control[type="file"]:not([readonly]) {
    background: transparent;
}

/* Align with our text fields */
.form-select:disabled{
    background-color: #e4e6e7;
}