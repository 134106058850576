@use "sass:math";

.embed-responsive-help {
  padding-bottom: 100%;

  .embed-responsive-item {
    position: fixed;
    top: inherit;
    bottom: inherit;
    left: inherit;
  }
}

.embed-responsive-a4 {
  padding-bottom: math.percentage(math.div(297, 210));
}

.embed-responsive-email {
  padding-bottom: math.percentage(math.div(400, 300));
}
