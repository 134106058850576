// default
$spinner-border-width:                              3px;
$spinner-width:                                     1.375rem;
$spinner-height:                                    1.375rem;

// lg
$spinner-border-width-lg:                           4px;
$spinner-width-lg:                                  1.75rem;
$spinner-height-lg:                                 1.75rem;

// sm
$spinner-border-width-sm:                           3px;
$spinner-width-sm:                                  1.125rem;
$spinner-height-sm:                                 1.125rem;

// xs
$spinner-border-width-xs:                           2px;
$spinner-width-xs:                                  .875rem;
$spinner-height-xs:                                 .875rem;
