.close-md {
  font-size: $font-size-base * 1.5;
}

.close-lg {
  font-size: $font-size-base * 2;
}

.close-xl {
  font-size: $font-size-base * 3;
}
