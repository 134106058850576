table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;

  td, th {
    box-sizing: content-box;
  }

  td.dataTables_empty, th.dataTables_empty {
    text-align: center;
  }

  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dt-length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }

    select {
      width: auto;
      display: inline-block;
    }
  }

  &.dt-search {
    text-align: right;

    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
    }

    input {
      margin-left: .5em;
      display: inline-block;
      width: auto;
    }
  }

  &.dt-info {
    padding-top: 8px;
    white-space: nowrap;
  }

  &.dt-paging {
    margin: 0;
    white-space: nowrap;
    text-align: right;

    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
      justify-content: flex-end;
    }
  }

  &.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
}

table.dataTable thead {
  th {
    border-bottom: 0;
  }

  > tr > {
    th {
      &.sorting_asc, &.sorting_desc, &.sorting {
        padding-right: 30px;
      }
    }

    td {
      &.sorting_asc, &.sorting_desc, &.sorting {
        padding-right: 30px;
      }
    }

    th:active, td:active {
      outline: none;
    }
  }

  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
  }

  .sorting::after, .sorting_asc::after, .sorting_desc::after, .sorting_asc_disabled::after, .sorting_desc_disabled::after {
    display: block;
    position: absolute;
    right: 4px;
    bottom: 2px;
    font-family: iconic-sm, sans-serif;
    opacity: .6;
  }

  .sorting::after {
    opacity: .1;
    content: "\e1f4";

    /* sort */
  }

  .sorting_asc::after {
    content: "\e246";

    /* sort-by-attributes */
  }

  .sorting_desc::after {
    content: "\e247";

    /* sort-by-attributes-alt */
  }

  .sorting_asc_disabled::after, .sorting_desc_disabled::after {
    color: $gray-lighter;
  }
}

div {
  &.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
  }

  &.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    thead {
      .sorting::after, .sorting_asc::after, .sorting_desc::after {
        display: none;
      }
    }

    tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
  }

  &.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
  }
}

@media screen and (width <= 767px) {
  div.dataTables_wrapper div {
    &.dataTables_length, &.dt-search, &.dt-info, &.dt-paging {
      text-align: center;
    }
  }
}

table {
  &.dataTable.table-condensed {
    > thead > tr > th {
      padding-right: 20px;
    }

    .sorting::after, .sorting_asc::after, .sorting_desc::after {
      top: 6px;
      right: 6px;
    }
  }

  &.table-bordered.dataTable {
    border-collapse: separate !important;
    border-color: transparent;

    th, td {
      border-left-width: 0;
      border-color: white;
    }

    th:last-child, td:last-child {
      border-right-width: 0;
    }

    tbody {
      th, td {
        border-bottom-width: 0;
      }
    }
  }
}

div {
  &.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }

  &.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;

    > div[class^="col-"] {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: rgb(0 0 0 / 10%);
      }

      &:nth-of-type(even) {
        background-color: rgb(0 0 0 / 5%);
      }
    }
  }
}

@media (width >= 34em) {
  .form-inline .form-control {
    min-width: auto;
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .dt-length {
    text-align: left !important;
  }

  .dt-search {
    text-align: right !important;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .dt-search {
    text-align: left !important;
  }
}

// Fixing the problem with `calc` height created by Boostrap
.dataTables_wrapper {
  select.form-control {
    &:not([size], [multiple]) {
      height: inherit;
    }
  }
}

.mw-2em {
  max-width: 2em;
}