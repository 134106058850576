.border-muted {
  border-color: rgba($black, .125) !important;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-width-2 {
  border-width: .125rem;
}

.border-width-3 {
  border-width: .25rem;
}

.rounded-top-4 {
  border-top-right-radius: .4rem;
  border-top-left-radius: .4rem;
}

.rounded-top-5 {
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
}

.rounded-start-1 {
  border-radius: .25rem 0 0 .25rem;
}

.rounded-end-1 {
  border-radius: 0 .25rem .25rem 0;
}