@import "variables";

.toast-dialog {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: $toast-dialog-padding;
  right: $toast-dialog-padding;
  width: $toast-dialog-button-size;
  height: $toast-dialog-button-size;
  transform: translateY(70px);
  transition: all $toast-dialog-transition-speed ease-out;
  border-radius: 50%;
  background-color: $toast-dialog-bg-color;
  z-index: $zindex-popover;

  .toast-dialog-button {
    text-align: center;
    line-height: 1;
    color: $toast-dialog-button-color;

    i {
      font-size: $h4-font-size;
    }

    span {
      display: block;
      font-size: $font-size-xs;
    }
  }

  &.enter {
    transform: translateY(0);
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 14%);

    &:hover {
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    }
  }

  &.expand {
    width: $toast-dialog-width;
    height: $toast-dialog-height;
    max-height: $toast-dialog-height;
    border-radius: $toast-dialog-border-radius;
    background-color: transparent;
    cursor: auto;
  }
  
  .toast {
    display: flex;
    flex-direction: column;
    position: absolute;
    inset: 0;
    width: 1px;
    max-width: $toast-dialog-width;
    height: 1px;
    margin: auto;
    background-color: $toast-dialog-body-bg-color;
    border-radius: 50%;
    border: 0;
    opacity: 1;
    transition: all $toast-dialog-transition-speed ease-out;
    backdrop-filter: none;

    &.enter {
      border-radius: $toast-dialog-border-radius;
      width: auto;
      height: auto;
    }
  }

  .toast-header {
    background-color: $toast-dialog-header-bg-color;
  }

  .toast-body {
    background-color: $toast-dialog-body-bg-color;
    overflow: auto;
  }
}

/* Animation */

/* -------------------------------------- */
@keyframes show-chat-even {
  0% {
    margin-left: -$toast-dialog-width;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes show-chat-odd {
  0% {
    margin-right: -$toast-dialog-width;
  }

  100% {
    margin-right: 0;
  }
}
