/*! THIS IS AN FILE IS AUTOMATICALLY GENERATED: DO NOT EDIT */
@import './node_modules/sass-svg-uri/svg-uri';
@import '../../src/theme/theme';

/*
 *
 * Aurion UI — Bootstrap
 * 
 */

@import './node_modules/bootstrap/scss/bootstrap';
@import '../../src/bootstrap/reboot/overrides';
@import '../../src/bootstrap/type/overrides';
@import '../../src/bootstrap/alerts/overrides';
@import '../../src/bootstrap/borders/overrides';
@import '../../src/bootstrap/buttons/overrides';
@import '../../src/bootstrap/text/overrides';
@import '../../src/bootstrap/modal/overrides';
@import '../../src/bootstrap/forms/overrides';
@import '../../src/bootstrap/badge/overrides';
@import '../../src/bootstrap/breadcrumb/overrides';
@import '../../src/bootstrap/list-group/overrides';
@import '../../src/bootstrap/custom-forms/overrides';

// Fonts
@import '../../src/theme/fonts';
@import './node_modules/sass-svg-uri/svg-uri';
@import '../../src/theme/theme';

/*
 *
 * Aurion UI — Bootstrap extend
 * 
 */

@import '../../src/bootstrap/reboot/extend';
@import '../../src/bootstrap/clearfix/extend';
@import '../../src/bootstrap/positions/extend';
@import '../../src/bootstrap/type/extend';
@import '../../src/bootstrap/alerts/extend';
@import '../../src/bootstrap/close/extend';
@import '../../src/bootstrap/code/extend';
@import '../../src/bootstrap/sizing/extend';
@import '../../src/bootstrap/background-variant/extend';
@import '../../src/bootstrap/buttons/extend';
@import '../../src/bootstrap/text/extend';
@import '../../src/bootstrap/nav/extend';
@import '../../src/bootstrap/navbar/extend';
@import '../../src/bootstrap/modal/extend';
@import '../../src/bootstrap/images/extend';
@import '../../src/bootstrap/jumbotron/extend';
@import '../../src/bootstrap/forms/extend';
@import '../../src/bootstrap/tables/extend';
@import '../../src/bootstrap/cards/extend';
@import '../../src/bootstrap/badge/extend';
@import '../../src/bootstrap/list-group/extend';
@import '../../src/bootstrap/custom-forms/extend';
@import '../../src/bootstrap/embed/extend';
@import '../../src/bootstrap/borders/extend';

@import './node_modules/sass-svg-uri/svg-uri';
@import '../../src/theme/theme';

/*
 *
 * Aurion UI — Components
 *
 */

@import '../../src/components/b4c-buttons/styles';
@import '../../src/components/b4c-custom-switch/styles';
@import '../../src/components/b4c-dropzone/styles';
@import '../../src/components/b4c-forms/styles';
@import '../../src/components/b4c-tageditor/styles';
@import '../../src/components/b4c-spinners/styles';
@import '../../src/components/autocomplete/styles';
@import '../../src/components/datatables/styles';
@import '../../src/components/datatables-bootstrap4/styles';
@import '../../src/components/flatpickr/styles';
@import '../../src/components/loading/styles';
@import '../../src/components/treeview/styles';
@import '../../src/components/placeholder/styles';
@import '../../src/components/toast-dialog/styles';

@import './node_modules/sass-svg-uri/svg-uri';
@import '../../src/theme/theme';

/*
 *
 * Aurion UI — Custom
 *
 */

@import '../../src/pages/actionitems/scss/styles';
@import '../../src/pages/signin/scss/styles';
