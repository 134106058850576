.custom-control-input:checked ~ .custom-control-indicator {
  color: white;
  background-color: #387bbd;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 0.0625rem white, 0 0 0 0.125rem #387bbd;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: white;
  background-color: #84c6ff;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
  background-color: #eee;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #767676;
  cursor: not-allowed;
}

.custom-control-indicator {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
          user-select: none;
  background-color: #bdc0c3;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  box-shadow: inset 0 0.25rem 0.25rem rgb(0 0 0 / 10%);
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #387bbd;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='white' d='M0 2h4'/%3E%3C/svg%3E");
  box-shadow: none;
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E");
}

.custom-controls-stacked .custom-control {
  float: left;
  clear: left;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}
