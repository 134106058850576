@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  z-index: 1000;

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 32px;
    height: 32px;
    margin-top: -32px;
    margin-left: -16px;
    border-radius: 50%;
    border: 3px solid rgb(0 0 0 / 20%);
    border-top-color: $primary;
    animation: spinner .75s linear infinite;
  }
}

.spinner-sm {
  &::before {
    width: 16px;
    height: 16px;
    margin-top: -16px;
    margin-left: -16px;
    border-width: 2px;
  }
}

.spinner-lg {
  &::before {
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    border-width: 6px;
  }
}
