@use "sass:color";

// Bootstrap variables
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';

// Options
$enable-rounded:                                    true;
$enable-shadows:                                    false;
$enable-gradients:                                  false;
$enable-transitions:                                true;
$enable-reduced-motion:                             true;
$enable-hover-media-query:                          false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                               true;
$enable-button-pointers:                            true;
$enable-negative-margins:                           true;
$enable-responsive-font-sizes:                      true;
$enable-validation-icons:                           true;
$enable-deprecation-messages:                       true;

$body-bg:                                           $gray-100;
$body-color:                                        $gray-900;
$text-muted:                                        $gray-500;
$enable-shadows:                                    true;
$border-radius:                                     .175rem;
$border-radius-lg:                                  .25rem;
$border-radius-sm:                                  .125rem;

$min-contrasted-threshold:                          255;

// Navs
$nav-pills-border-radius:                           $border-radius-sm;

// Navbar
$navbar-height-desktop:                             3.75rem;
$navbar-height-mobile:                              2.5rem;
$navbar-dark-color:                                 rgba($white,.6);
$navbar-dark-hover-color:                           rgba($white,1);
$navbar-padding-y:                                  0;
$navbar-padding-x:                                  0;
$navbar-nav-link-padding-x:                         1rem;
$nav-link-height:                                   $navbar-height-desktop;
$navbar-brand-height:                               $navbar-height-desktop;
$navbar-brand-padding-x:                            1.5rem;
$navbar-brand-padding-y:                            ($navbar-brand-height - $navbar-brand-font-size) * 0.5;
$navbar-inverse-color:                              $brand-primary-lighter;

// Alerts
$alert-bg-level:                                    0;
$alert-border-level:                                2;
$alert-color-level:                                 -10;

// Breadcrumb
$breadcrumb-padding-x:                              0;
$breadcrumb-padding-y:                              0;
$breadcrumb-margin-bottom:                          0;
$breadcrumb-bg:                                     transparent;
$breadcrumb-border-radius:                          0;
$breadcrumb-divider-color:                          $gray-300;
$breadcrumb-active-color:                           $gray-700;

// Form
$input-placeholder-color:                           $gray-300;
$input-disabled-bg:                                 $gray-100;

// Table
$table-dark-bg:                                     $gray-600;
$table-dark-border-color:                           color.adjust($table-dark-bg, $lightness: 7.5%);

// Other
// $list-group-action-hover-color:                  $link-color;
$list-group-hover-bg:                               $brand-primary-lightest;

// Custom forms
$custom-control-checked-indicator-color:            red;
$custom-checkbox-radius:                            $border-radius;
$custom-checkbox-checked-icon:                      url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
$custom-checkbox-indeterminate-bg:                  $link-color;
$custom-checkbox-indeterminate-indicator-color:     $custom-control-checked-indicator-color;
$custom-checkbox-indeterminate-icon:                url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E");
$custom-checkbox-indeterminate-box-shadow:          none;

// Fonts
$font-family-sans-serif:                            "Open Sans", Tahoma, Geneva, Helvetica, Arial, sans-serif;
$font-family-monospace:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:                                  $font-family-sans-serif;

// Custom variables
$OpenSansPath:                                      "../fonts/OpenSans";
$IconicPath:                                        "../fonts/Iconic";
$GlyphiconsPath:                                    "../fonts/Glyphicons";

$tap-highlight-color:                               rgb(0 0 0 / 20%);
$font-size-xs:                                      ($font-size-base * .75);
$line-height-xs:                                    1.25;

$avatar-size:                                       2.5rem;
$avatar-size-lg:                                    5rem;

$accessibility-testing:                             false;

