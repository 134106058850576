@use "sass:math";
@use "sass:color";

$table-cell-padding: .5rem;
$table-dark-color: white;

.table-condensed {
  thead {
    th {
      padding: 0;
      padding-right: .25rem;
    }
  }

  tbody {
    th,
    td {
      padding: 0;
      padding-right: .25rem;
    }
  }
}

.table-xs {
  th,
  td {
    padding: math.div($table-cell-padding, 6);
    font-size: $font-size-xs;
  }

  tfoot {
    th,
    td {
      padding: math.div($table-cell-padding, 6);
      font-weight: normal;
    }
  }
}

.table-sm {
  th,
  td {
    padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
    font-size: $font-size-sm;
  }

  tfoot {
    th,
    td {
      padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
      font-weight: normal;
    }
  }
}

.table-md {
  thead {
    th {
      font-size: $font-size-xs;
    }
  }

  td {
    line-height: 1rem;
  }
}

.table-raised {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 10px 0 rgb(0 0 0 / 15%);
}

.table-responsive {
  .table-raised {
    margin: 4px 0 10px;
  }
}

.aurion-thead-color {
  background-color: rgb(56 123 189 / 20%);
}

.table-clickable-row {
  cursor: pointer;
  color: $link-hover-color !important;

  &:hover {
    --bs-table-accent-bg: $link-color !important;

    background: $link-color !important;
    color: white !important;

    td {
      background: $link-color !important;
      border-color: gray;
    }
  }

  &.active {
    td {
      background-color: $brand-primary-light !important;
      color: white !important;
    }
  }

  &.selected {
    td {
      background-color: $brand-primary-dark !important;
      color: white !important;

      &:first-child {
        &::before {
          display: inline-block;
          margin-left: -1.25rem;
          margin-right: .5rem;
          line-height: 1;
          vertical-align: baseline;
          content: '\e153';
          font-family: iconic-sm, sans-serif;
          font-size: .75rem;
          text-align: center;
          box-sizing: content-box;
        }
      }
    }
  }

  &.text-danger {
    &:hover {
      background: rgba($brand-danger,1);
      color: white !important;
    }
  }
}

.table-clickable-cell {
  cursor: pointer;
  color: $link-hover-color !important;

  &:hover {
    background: $link-color !important;
    color: white !important;
  }

  &.table-danger {
    &:hover {
      background-color: rgba($brand-danger,1);
    }
  }

  &[aria-describedby] {
    outline: .125rem solid $brand-primary;
    outline-offset: -.0625rem;

    &.table-danger {
      outline-color: $brand-danger;
    }
  }
}

.table-clickable-cell-add {
  &:hover {
    background: $brand-success !important;
  }
}

td {
  &.table-clickable-cell-add {
    &:hover {
      &::after {
        font-size: 80%;
        content: "+Add";
      }
    }
  }
}

.table-week {
  &.table-xs {
    margin-bottom: 0;

    thead {
      th {
        border: 0;
        border-right: 0.4rem solid;
        border-left: 0.4rem solid;

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-right: 0;
        }
      }
    }

    tbody {
      td {
        padding-top: 0;
        border-top: 0;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        .form-control {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
}

td.required.empty-cell {
  &::after {
    font-size: $font-size-base;
    font-weight: bold;
    color: $brand-danger;
    content: "*";
    line-height: 1px;
  }
}

// Timekeeper Weekly
.table-timekeeper-weekly {
  background-color: white;

  &.table-bordered {
    border: .5rem solid $gray-lighter;
  }

  &.table {
    thead {
      th {
        span {
          text-transform: capitalize;
          color: rgb(255 255 255 / 40%);
        }
      }

      th[scope="col"] {
        background-color: $gray-lighter;
        color: $gray;
      }

      th[scope="row"] {
        background-color: $gray-lighter;
      }

      th[scope="rowgroup"] {
        background-color: $gray-lighter;
        border-right: .5rem solid $gray-lighter;
        color: $gray;
        text-transform: none;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        vertical-align: top;
      }
    }

    tbody {
      th {
        background-color: rgb(0 0 0 / 10%);

        .text-muted {
          color:rgb(0 0 0 / 10%);
        }
      }

      th[scope="rowgroup"] {
        background-color: rgb(0 0 0 / 10%);
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="row"] {
        background-color: $gray-lighter;
        color: $gray;

        strong {
          font-size: $font-size-base;
          color: $gray-dark;
        }
      }

      td[scope="colgroup"] {
        background-color: rgb(0 0 0 / 10%);
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        color: white;
      }
    }

    tfoot {
      border-top: .5rem solid $gray-lighter;

      th {
        background-color: rgb(0 0 0 / 10%);
      }

      th[scope="row"] {
        background-color: $gray-lighter;
        color: $gray-lightest;
      }

      th[scope="col"] {
        background-color: $gray-light;
      }

      th[scope="rowgroup"] {
        border-right: .5rem solid $gray-lighter;
      }

      th[scope="colgroup"] {
        background-color: $gray-darker;
        color: white;
      }

      td[scope="colgroup"] {
        border-right: .5rem solid $gray-lighter;
      }

      tr.table-success {
        > td[scope="colgroup"] {
          background-color: rgba($brand-success,.2);
        }
      }

      tr.table-danger {
        > td[scope="colgroup"] {
          background-color: rgba($brand-danger,.2);
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .table-responsive-collapse {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    tbody {
      &.thead-dark {
        td {
          &::before {
            color: $table-dark-color;
            background-color: $table-dark-bg;
            border-color: $table-dark-border-color;
          }
        }
      }

      tr {
        border-bottom: $table-border-width solid $table-border-color;
        margin-bottom: 3rem;
      }

      td {
        position: relative;
        min-height: ($table-cell-padding * 2) + $line-height-base;
        padding: $table-cell-padding;
        padding-left: 50%;
        border: none;
        border-bottom: $table-border-width solid $table-border-color;
        white-space: normal;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: calc(50% - (12px / 1));
          height: 100%;
          padding: $table-cell-padding;
          border-bottom: 1px solid transparent;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          content: attr(data-header);
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .table-bordered {
      tbody {
        tr {
          border: $table-border-width solid $table-border-color;
        }
      }
    }

    .table-striped {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: transparent;
          }
        }

        td {
          &:nth-of-type(odd) {
            background-color: rgb(0 0 0 / 5%);
          }
        }

        &.thead-dark {
          td {
            &:nth-of-type(odd) {
              &::before {
                background-color: color.adjust($table-dark-bg, $lightness: 5%);
              }
            }
          }
        }
      }
    }

    .table-bordered {
      tbody {
        td {
          &::before {
            border-right: 1px solid $table-border-color;
          }
        }
      }
    }

    .table-xs {
      tr {
        margin-bottom: 1rem;
      }

      td {
        min-height: ($table-cell-padding * 0.5) + $line-height-xs;
        padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
        padding-left: 50%;

        &::before {
          width: calc(50% - (12px / 2));
          min-height: ($table-cell-padding * 0.5) + $line-height-xs;
          padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
        }
      }
    }

    .table-sm {
      tr {
        margin-bottom: 2rem;
      }

      td {
        min-height: ($table-cell-padding * 0.5) + $line-height-sm;
        padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
        padding-left: 50%;

        &::before {
          width: calc(50% - (12px / 2));
          min-height: ($table-cell-padding * 0.5) + $line-height-sm;
          padding: ($table-cell-padding * 0.25) ($table-cell-padding * 0.5);
        }
      }
    }

    .table-bordered {
      border: none;
    }

    .table-raised {
      box-shadow: none;

      tr {
        margin: 4px 4px 2rem;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 10px 0 rgb(0 0 0 / 15%);
      }
    }
  }
}

/*
* Danger rows should not be striped different colours.
*/
.table-danger {
  --bs-table-accent-bg: none !important;
}