.text-black-25 {
  color: rgb(0 0 0 / 25%) !important;
}

.text-black-75 {
  color: rgb(0 0 0 / 75%) !important;
}

.text-white-25 {
  color: rgb(255 255 255 / 25%) !important;
}

.text-white-75 {
  color: rgb(255 255 255 / 75%) !important;
}

.text-small,
.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-label {
  color: $gray-300;
}

.text-white {
  color: white;
}

.text-talent {
  color: $brand-talent;
}

.text-selfservice {
  color: $brand-selfservice;
}

.text-action {
  color: $color-action;
}

.text-applicants {
  color: $color-applicants;
}

.text-filter {
  color: $color-filter;
}

.text-more {
  color: $color-more;
}

.text-stages {
  color: $color-stages;
}

.text-twitter {
  color: $color-twitter;
}

.text-facebook {
  color: $color-facebook;
}

.text-googleplus {
  color: $color-googleplus;
}

.text-youtube {
  color: $color-youtube;
}

.text-linkedin {
  color: $color-linkedin;
}

.text-instagram {
  color: $color-instagram;
}

.text-pinterest {
  color: $color-pinterest;
}

.text-vimeo {
  color: $color-vimeo;
}
