/* Open Sans @font-face kit */
@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/Light/OpenSans-Light.eot');
  src: url('#{$OpenSansPath}/Light/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/Light/OpenSans-Light.woff') format('woff'),
       url('#{$OpenSansPath}/Light/OpenSans-Light.ttf') format('truetype'),
       url('#{$OpenSansPath}/Light/OpenSans-Light.svg#OpenSansLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/LightItalic/OpenSans-LightItalic.eot');
  src: url('#{$OpenSansPath}/LightItalic/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/LightItalic/OpenSans-LightItalic.woff') format('woff'),
       url('#{$OpenSansPath}/LightItalic/OpenSans-LightItalic.ttf') format('truetype'),
       url('#{$OpenSansPath}/LightItalic/OpenSans-LightItalic.svg#OpenSansLightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/Regular/OpenSans-Regular.eot');
  src: url('#{$OpenSansPath}/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/Regular/OpenSans-Regular.woff') format('woff'),
       url('#{$OpenSansPath}/Regular/OpenSans-Regular.ttf') format('truetype'),
       url('#{$OpenSansPath}/Regular/OpenSans-Regular.svg#OpenSansRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/Italic/OpenSans-Italic.eot');
  src: url('#{$OpenSansPath}/Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/Italic/OpenSans-Italic.woff') format('woff'),
       url('#{$OpenSansPath}/Italic/OpenSans-Italic.ttf') format('truetype'),
       url('#{$OpenSansPath}/Italic/OpenSans-Italic.svg#OpenSansItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/Bold/OpenSans-Bold.eot');
  src: url('#{$OpenSansPath}/Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/Bold/OpenSans-Bold.woff') format('woff'),
       url('#{$OpenSansPath}/Bold/OpenSans-Bold.ttf') format('truetype'),
       url('#{$OpenSansPath}/Bold/OpenSans-Bold.svg#OpenSansBold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$OpenSansPath}/BoldItalic/OpenSans-BoldItalic.eot');
  src: url('#{$OpenSansPath}/BoldItalic/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('#{$OpenSansPath}/BoldItalic/OpenSans-BoldItalic.woff') format('woff'),
       url('#{$OpenSansPath}/BoldItalic/OpenSans-BoldItalic.ttf') format('truetype'),
       url('#{$OpenSansPath}/BoldItalic/OpenSans-BoldItalic.svg#OpenSansBoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}
