.treeview {
  .list-group {
    margin-top: 0;
  }

  .list-group-item {
    padding-left: 2.5rem;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: $font-size-sm;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding-top: .75rem;
      font-size: $font-size-xs;
    }

    &:not(.node-disabled) {
      &:hover {
        color: white !important;

        .expand-icon {
          &.glyphicon-plus {
            &::before {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E");
            }
          }

          &.glyphicon-minus {
            &::before {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }

  span {
    &.indent {
      float: left;
      width: 1.75rem !important;
      height: 1.5rem;
      margin-right: 0 !important;
      margin-left: 0!important;
    }

    &.icon {
      display: none;
      width: 0 !important;
      margin-right: .25rem !important;
      margin-left: -.25rem !important;
    }

    &.expand-icon {
      display: inline-block;

      &:hover {
        &::before {
          background-color: $brand-primary;
        }
      }

      &::before {
        display: block;
        position: absolute;
        top: 0;
        width: 1.75rem;
        height: 100%;
        margin-left: -2.25rem;
        content: "";
        background-size: 1.75rem 1.75rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: rgb(0 0 0 / 10%);
      }

      &.glyphicon-plus {
        &::before {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$link-color}' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E");
        }
      }

      &.glyphicon-minus {
        &::before {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$link-color}' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  .node-selected {
    font-weight: bold;

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: .5rem;
      width: 1.75rem;
      height: 100%;
      content: "";
      background-color: white;
      background-size: 1.75rem 1.75rem;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$brand-success}' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
    }

    &:hover {
      background-color: $link-color !important;

      &::after {
        background-color: $link-color !important;
      }
    }
  }

  .node-disabled {
    cursor: not-allowed;
  }
}

