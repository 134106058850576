@use "sass:color";
@import '../deprecated/mixins';


$list-group-link-color:           $link-color;
$list-group-link-hover-color:     $link-color;

.list-group-sm {
  font-size: $font-size-sm;

  .list-group-item {
    padding: .75rem;

    .list-group-item-heading {
      margin-bottom: .125rem;
      font-weight: 700;
    }
  }
}

.list-group-secondary {
  .list-group-item {
    &:not(.active) {
      background-color: $gray-light;
      color: white;

      &.disabled {
        color: $gray-lighter;
      }
    }
  }

  a,
  button {
    &.list-group-item {
      &:not(.active) {
        &:active,
        &:focus,
        &:hover {
          background-color: $gray;
        }
      }
    }
  }
}

.list-group-transparent {
  .list-group-item {
    &:not(.active) {
      background-color: transparent;
    }
  }

  a,
  button {
    &.list-group-item {
      &:not(.active) {
        &:active,
        &:focus,
        &:hover {
          background-color: $gray-lighter;
        }
      }
    }
  }
}

.list-group-outline {
  .list-group-item {
    &.active {
      position: relative;
      margin-top: .2rem;
      margin-bottom: .2rem;
      padding: .6rem;
      border-color: $brand-primary;
      border-width: .2rem;
      border-radius: .2rem;
      background-color: transparent;
      color: $gray-darker;

      .list-group-item-heading {
        color: $gray-darker;
      }

      .list-group-item-text {
        color: $gray-dark;
      }

      .text-danger {
        color: $brand-danger;
      }
    }
  }
}

.list-group-checkbox {
  .list-group-item {
    padding-left: 2rem;

    &.active {
      padding-left: 2rem;

      i {
        &.iconic-circle-check {
          margin-left: -1.6rem;
          font-size: $font-size-lg;
        }
      }
    }

    .c-input {
      left: .5rem;
      margin-left: -2rem;
    }
  }
}

.list-group-arrow {
  .list-group-item {
    &.active {
      &::after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-left-color: $brand-primary;
        border-width: 1rem;
        margin-top: -1rem;
      }
    }
  }
}

.list-group-checkbox {
  .list-group-item {
    padding-left: 2.5rem;

    &::before {
      display: block;
      position: absolute;
      top: .75rem;
      left: .75rem;
      font-family: iconic-sm;
      font-size: $font-size-lg;
      content: "\e22d";
      opacity: .2;
    }
  }

  .list-group-item-checked {
    &::before {
      color: $brand-success;
      content: "\e159";
      opacity: 1;
    }
  }
}

.list-group-item {
  min-height: 2.5rem;

  &.active-warning {
    background-color: $brand-warning;
    border-color: $brand-warning;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: .5rem;
  }

  .media {
    margin-bottom: 0;
  }
}

a,
button {
  &.list-group-item {
    &.active,
    &.active-warning {
      &:hover {
        background-color: $list-group-hover-bg;
        border-color: $list-group-hover-bg;

        .label-default {
          color: $list-group-hover-bg;
        }
      }
    }
  }
}

.list-group-item-split {
  padding: 0;

  .list-group-item-more {
    display: block;
    position: absolute;
    float: right;
    right: 0;
    width: 6rem;
    height: 100%;
    background-color: $brand-primary-lightest;

    @include hover-focus {
      color: white;
      background-color: $brand-primary;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 2.5rem;
    }
  }
}

.list-group-item-link {
  display: block;
  width: auto;
  padding: .75rem 1.25rem;
  color: $list-group-link-color;

  @include hover-focus {
    color: $list-group-link-hover-color;
    background-color: $list-group-hover-bg;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: .5rem;
  }
}

.list-group-item-more {
  padding-right: 3.5rem;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-right: 1.5rem;
  }

  &::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-family: iconic-sm;
    font-size: $font-size-base;
    content: "\e157";
    color: $link-color;

    @media (max-width: map-get($grid-breakpoints, md)) {
      right: .5rem;
    }
  }

  &.active {
    &::after {
      color: white;
    }
  }

  @include hover-focus {
    border-color: $brand-primary;

    &::after {
      color: white;
    }

    .badge-primary {
      background-color: white !important;
      color: $brand-primary !important;
    }
  }

  .badge {
    display: block;
    position: absolute;
    top: 50%;
    right: 2.5rem;
    transform: translateY(-50%);

    @media (max-width: map-get($grid-breakpoints, md)) {
      right: 2rem;
    }
  }
}

.list-group-item-back {
  padding-left: 2.5rem;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: 1.5rem;
  }

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-family: iconic-sm;
    font-size: $font-size-base;
    content: "\e156";
    color: $link-color;

    @media (max-width: map-get($grid-breakpoints, md)) {
      left: .5rem;
    }
  }

  &.active {
    &::before {
      color: white;
    }
  }

  strong {
    margin-left: 2rem;
  }
}

.list-group-item {
  color: inherit;

  &.active {
    .badge-primary {
      background-color: $list-group-link-hover-color !important;
      color: $list-group-hover-bg;
    }

    &.active-warning {
      .badge-primary {
        color: $brand-warning;
      }
    }
  }

  &.active,
  &.active-warning {
    .list-group-item-link {
      color: white;
    }
  }
}

a,
button {
  &.list-group-item {
    @include hover-focus {
      border-color: $link-color;
      background-color: $link-color;
      color: white;

      &::before {
        color: white;
        opacity: 1;
      }

      &::after {
        color: white;
      }

      .text-success {
        color: $list-group-link-hover-color !important;
      }

      .badge-primary {
        background-color: $list-group-link-hover-color !important;
        color: $list-group-hover-bg !important;
      }
    }

    &.active,
    &.active-warning {
      &:hover {
        background-color: $link-color;
        border-color: $link-color;
        color: white;

        .badge-default {
          color: $link-color;
        }
      }
    }
  }
}


// When using list-group-item-text groupings to render small list groups, such
// as on the vacancies card in mobile, this optional class flexes the items
// to keep them on one row.
.keep-together {
  display: flex;

  > * {
    flex: 1 0 auto;
  }
}

.keep-together-wrapper {
  display: inline-block;
  max-width: 50%;
}



.list-group-item {
  &.active {
    .badge-primary {
      color: $primary;
      background-color: white;
    }

    &.open,
    &.closed {
      color: white;
    }
  }

  &.open {
    color: $gray-400;
  }

  &.closed {
    i.iconic {
      color: $primary;
    }
  }
}


.recruit {
  &.stages {
    .list-group-item {
      &.active {
        background-color: $color-applicants;
        border-color: $color-applicants;
        color: white;
      }
    }
  }

  &.applicants {
    .list-group-item {
      &.active {
        background-color: color.mix($color-applicants, black, 75%);
        border-color: color.mix($color-applicants, black, 75%);
        color: white;
      }
    }
  }

  .list-group-item {
    background-color: transparent;
    border-color: rgb(255 255 255 / 10%   );
    color: rgb(255 255 255 / 80%);

    &.disabled {
      color: rgb(255 255 255 / 40%);
    }

    &.text-danger {
      color: red !important;
    }
  }

  .list-group-item-action {
    &:active,
    &:focus,
    &:hover {
      background-color: $link-color;
      border-color: $link-color;
      color: white !important;
    }
  }
}
