body {
  overflow-x: hidden;     // Prevent scroll on narrow devices
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

legend {
  font-size: $font-size-lg;
}

var {
  font-style: normal;
}
