.nav-scroller {
  position: relative;
  z-index: 2;
  height: $navbar-height-desktop;
  overflow-y: hidden;

  .nav {
    display: -webkit-box;
    display: flexbox;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: white;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

.navbar-employee {
  height: $navbar-height-desktop;
  line-height: 1;
}

.navbar.offcanvas-desktop {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
}

.navbar-brand {
  height: $navbar-height-desktop;
  margin: 0;
  margin-right: ($navbar-brand-padding-x * 2);
  padding: 1rem $navbar-brand-padding-x 0;
  background-color: $brand-primary-dark;
  line-height: $navbar-brand-font-size;

  &::after {
    top: 100%;
    border: solid transparent;
    position: absolute;
    width: 0;
    height: 0;
    margin-top: -$navbar-height-desktop;
    margin-left: $navbar-brand-padding-x;
    border-top-color: $brand-primary-dark;
    border-left-color: $brand-primary-dark;
    border-width: ($navbar-height-desktop * 0.5);
    pointer-events: none;
    content: " ";
  }

  img {
    max-height: ($navbar-height-desktop * 0.5);
  }
}

.navbar {
  > .dropdown-toggle {
    &:active {
      pointer-events: none;
    }
  }

  .navbar-nav {
    &.clicked {
      .dropdown {
        &:hover {
          > .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .navbar-toggler {
    width: ($navbar-height-mobile + ($navbar-height-mobile * 0.25));
    height: $navbar-height-mobile;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .dropdown-menu {
    margin-top: 0;
    border: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .dropdown-item {
      font-weight: 300;
    }
  }

  .dropdown-header {
    padding-left: 1rem;
  }
}

.navbar.bg-primary {
  .navbar-nav {
    &.clicked {
      .dropdown {
        &:hover {
          .nav-link {
            &:hover {
              background-color: $brand-primary-dark;
            }
          }
        }
      }
    }
  }

  .navbar-toggler {
    background-color: $brand;
    -webkit-tap-highlight-color: $brand-primary-dark;

    &:active,
    &:focus {
      background-color: $brand-primary-dark;
    }
  }

  .dropdown-menu {
    background-color: $brand-primary-dark;

    .dropdown-item {
      color: rgb(255 255 255 / 60%);

      &:active,
      &:focus,
      &:hover {
        background-color: $brand-primary;
        color: white;
      }

      &.active {
        background-color: transparent;
        color: white;
      }
    }
  }

  .dropdown-header {
    color: rgb(255 255 255 / 60%);
  }

  .dropdown-divider {
    border-top-color: rgb(255 255 255 / 20%);
  }

  .navbar-nav {
    &.clicked {
      .nav-item {
        &.show {
          .nav-link {
            background-color: $brand-primary-dark;
            color: rgb(255 255 255 / 60%) !important;

            &.active {
              color: white !important;
            }
          }
        }
      }
    }

    .nav-link {
      &:active,
      &:focus {
        color: rgb(255 255 255 / 60%) !important;
      }

      &:hover {
        background-color: rgb(255 255 255 / 10%);
        color: white !important;

        &.active {
          color: white !important;
        }
      }
    }
  }
}

.navbar-nav {
  .nav-link {
    height: $navbar-height-desktop;
    padding-top: ($navbar-height-desktop - $font-size-base) * 0.5;
    padding-bottom: 0;
    font-weight: 300;
    line-height: 1;

    &.dropdown-toggle-avatar {
      padding-top: ($navbar-height-desktop - $avatar-size) * 0.5;

      &::after {
        margin-left: .125rem;
        vertical-align: .125em;
      }
    }
  }
}

.nav-item {
  &.dropdown {
    .dropdown-menu {
      .dropdown-item {
        padding-left: 1.5rem;
        margin-right: .5rem;

        &.danger {
          &:hover {
            background-color: $danger;
          }
        }

        &.active {
          &::before {
            display: inline-block;
            top: .8rem;
            left: 1.325rem;
            margin-left: -1rem;
            margin-right: .25rem;
            line-height: 1;
            vertical-align: baseline;
            content: '\e153';
            font-family: iconic-sm;
            font-weight: 300;
            font-style: normal;
            font-size: .75rem;
            text-align: center;
            box-sizing: content-box;
          }
        }

        i {
          margin-left: -.5rem;
        }
      }
    }

    &.recruit {
      &.show {
        box-shadow: $box-shadow !important;

        &.action {
          background-color: $color-action;
        }

        &.applicants {
          background-color: $color-applicants;
        }

        &.filter {
          background-color: $color-filter;
        }

        &.more {
          background-color: $color-more;
        }

        &.stages {
          background-color: $color-stages;
        }

        .btn-action,
        .btn-applicants,
        .btn-filter,
        .btn-more,
        .btn-stages {
          background-color: inherit;
          border-color: transparent;
          box-shadow: none !important;
        }
      }

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .navbar-desktop {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      height: $navbar-height-mobile;
      padding-top: ($navbar-height-mobile - $font-size-base) * 0.5;
    }
  }

  .navbar-brand {
    height: $navbar-height-mobile;
    padding-top: ($navbar-height-mobile - $navbar-brand-font-size) * 0.5;
    padding-left: .75rem;

    &::after {
      margin-top: -$navbar-height-mobile;
      border-width: ($navbar-height-mobile * 0.5);
    }

    img {
      max-height: ($navbar-height-mobile * 0.5);
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-mobile {
    display: none;
  }
}
