@use "sass:color";

.list-group-item {
  line-height: 1;
}

.list-group-item-action {
  &:hover {
    &.bg-transparent {
      background-color: $brand-primary-lightest !important;
    }
  }

  &.border-dark {
    border-color: rgb(0 0 0 / 20%) !important;
  }
}

li {
  &.bg-primary {
    a {
      &.list-group-item-action {
        &:hover {
          background-color: $brand-primary-light !important;
        }
      }
    }
  }

  &.bg-warning {
    a {
      &.list-group-item-action {
        &:hover {
          background-color: color.mix($brand-warning, white, 75%) !important;
        }
      }
    }
  }
}
