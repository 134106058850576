$toast-dialog-width:                  320px;
$toast-dialog-height:                 480px;
$toast-dialog-transition-speed:       300ms;
$toast-dialog-padding:                1rem;
$toast-dialog-bg-color:               $info;
$toast-dialog-header-bg-color:        $info;
$toast-dialog-body-bg-color:          white;
$toast-dialog-button-color:           white;
$toast-dialog-button-size:            64px;
$toast-dialog-border-radius:          $border-radius;
