.back-col {
  z-index: 1000;
}

.inbox-col {
  z-index: 1000;

  .navbar {
    padding: 1rem;
  }
}

.message-col {
  z-index: 1010;

  .navbar {
    padding: 1rem;
  }
}

.message-content-col {
  z-index: 1020;

  .navbar {
    padding: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .inbox-col {
    .navbar {
      height: 58px;
    }
  }

  .message-col {
    > div {
      min-height: 500px;
      margin: -4px;
    }

    .navbar {
      height: 66px;
      padding: 1rem 1.25rem;
    }
  }

  .message-content-col {
    > div {
      min-height: 508px;
      margin: -8px -4px;
    }

    .navbar {
      height: 72px;
      padding: 1rem 1.5rem;
    }
  }
}
