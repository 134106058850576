.autocomplete-suggestions {
  border: 1px solid #95bbe0;
  border-radius: .25rem;
  background-color: $white;
  overflow: auto;
  box-shadow: 0 1px 1px rgb(0 0 0 / 7.5%) inset, 0 0 8px #95bbe0;
}

.autocomplete-suggestion {
  padding: .75rem;
  border-bottom: 1px solid $gray-100;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-no-suggestion {
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  color: $danger;
}

.autocomplete-suggestions {
  color: $gray-700;
  line-height: 1;

  strong {
    font-weight: bold;
    color: $black;
  }
}

.autocomplete-group {
  padding: .75rem;

  strong {
    display: block;
    border-bottom: 1px solid $gray-400;
  }
}

.autocomplete-selected {
  background-color: $primary;
  color: $white;

  strong {
    color: $white;
  }
}
