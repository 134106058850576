.nav-link {
  &.active {
    color: $gray-700;
  }
}

.nav-pointing {
  .nav-link {
    margin: .125rem;
    border: 1px solid $gray-200;

    &.active {
      position: relative;
      border-color: $nav-pills-link-active-bg;

      &::after {
        position: absolute;
        top: 50%;
        left: 100%;
        width: 0;
        height: 0;
        margin-top: -20px;
        margin-left: 1px;
        border: solid transparent;
        border-left-color: $nav-pills-link-active-bg;
        border-width: 20px;
        pointer-events: none;
        content: " ";
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .nav-pointing {
    .nav-link {
      &.active {
        &::after {
          top: 100%;
          left: 50%;
          margin-top: -5px;
          margin-left: -20px;
          border-color: rgb(255 0 0 / 0%);
          border-top-color: $nav-pills-link-active-bg;
        }
      }
    }
  }
}


ul.submenu-list {
  top: 3.7rem;
}