.breadcrumb-item {
  font-size: $font-size-sm;
  color: $gray-300;

  &.active {
    &::before {
      font-weight: normal;
    }
  }
}
