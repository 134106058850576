body.modal-open {
  overflow: hidden;
  position:fixed;
  width: 100%;
}

.modal-header {
  border-bottom: 0;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  background-color: $brand-primary-dark;
  color: rgb(255 255 255 / 80%);
}

.modal-footer {
  text-align: left;
}

@include media-breakpoint-down(lg) {
  .modal-lg {
    width: auto;

    &.modal-dialog {
      margin: 0;
      transition: none !important;
    }

    .modal-content {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
