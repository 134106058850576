.alert-custom {
  margin: 0;
  padding: .5rem;
  border: 0;
  border-left: .25rem solid rgba($info,1);
  background-color: rgba($info,.1);
  font-size: $font-size-sm;
}

.alert {
  border-width: 0 0 0 .5rem;
}
