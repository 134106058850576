@import "variables";
@import 'component-dropzone';

.dropzone {
  min-height: 4.5rem;

  .dz-message {
    margin-top: 0;
  }
}
