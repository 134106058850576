ol {
  &.list-number {
    list-style-type: none;
    margin: 0;
    margin-left: 3.5rem;
    padding: 0;
    counter-reset: li-counter;

    > li {
      position: relative;
      margin-bottom: 2rem;
      padding-left: .5rem;
      min-height: 2em;
      font-size: $font-size-base;

      &::before {
        position: absolute;
        top: 0;
        left: -2.5rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: $gray-lighter;
        color: white;
        font-size: $h4-font-size;
        line-height: 2rem;
        text-align: center;
        content: counter(li-counter);
        counter-increment: li-counter;
      }
    }
  }

  &.list-alphabet {
    > li {
      margin-bottom: 2rem;
    }
  }
}

.list-sm {
  > li {
    font-size: $font-size-sm;
  }
}

.list-lg {
  > li {
    margin-bottom: .5rem;
    font-size: $font-size-lg;
  }
}

.list-checkbox {
  ul {
    padding-left: 1.5rem;
  }

  li {
    padding-left: 0;
    list-style: none;
  }

  .custom-control {
    min-height: 1.5rem;
    margin-bottom: .5rem;
    background-color: rgb(0 0 0 / 10%);
    border-radius: 0;

    &:active,
    &:focus,
    &:hover {
      background-color: $link-color;
      color: white;

      .custom-control-input {
        &:not(:checked) {
          ~ .custom-control-indicator {
            background-color: $link-hover-color;
          }
        }
      }
    }
  }

  .custom-control-indicator {
    top: 0;
    width: 1.5rem;
    height: 100%;
    margin: 0;
    border-radius: 0;
    background-position: top .375rem center;
    box-shadow: none;
  }

  .custom-control-description {
    display: block;
    min-height: 1.5rem;
    padding: .125rem .375rem;
    font-size: $font-size-sm;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

ul.list-password-strength {
  -webkit-padding-start: 20px;

  > li {
    font-size: $font-size-xs;
    color: $gray-light;

    &.text-success {
      font-weight: bold;
      list-style: none;

      &::before {
        margin-right: .55rem;
        margin-left: -1.125rem;
        content: "\2713";
      }
    }
  }
}
