@import '../../bootstrap/deprecated/mixins';

.tag-editor {
  position: relative;
  overflow: hidden;
  outline: 0;
  min-height: 38px;
  margin: 0;
  padding: .125rem .25rem .125rem .0625rem;
  border: $input-btn-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  background-color: $input-bg;
  
  @include box-shadow($input-box-shadow);

  cursor: text;
  list-style-type: none;
  color: $input-color;
  font-size: $font-size-sm;
  background-image: none;
  background-clip: padding-box;

  li {
    display: block;
    float: left;
    overflow: hidden;
    margin: .25rem 0;
  }

  div {
    float: left;
    margin: 0;
    padding: 0;
  }

  input {
    vertical-align: inherit;
    border: inherit;
    outline: inherit;
    padding: inherit;
    margin: inherit;
    cursor: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-style: inherit;
    box-shadow: inherit;
    background: inherit;
    color: inherit;
  }

  &::-ms-clear {
    display: none;
  }

  .placeholder {
    margin: 0;
    padding: .25rem .75rem;
    font-size: $font-size-base;
    color: $input-color-placeholder;
  }

  .tag-editor-spacer {
    width: .5rem;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: transparent;
  }

  .tag-editor-tag {
    height: 1.5rem;
    padding: 0 1.5rem 0 .5rem;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    background-color: $gray-200;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    color: $gray-700;

    &.active {
      padding: 0 0 0 .22rem;
      border-color: transparent !important;
      background: transparent !important;

      + .tag-editor-delete {
        visibility: hidden;
        cursor: text;

        i {
          visibility: hidden;
          cursor: text;
        }
      }
    }
  }

  .tag-editor-delete {
    margin-top: .25rem;
    margin-left: -1.25rem;

    i {
      display: inline-block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$tag-editor-svg-close-color}' d='#{$tag-editor-svg-close}'/></svg>");
      background-size: 1rem 1rem;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:hover {
      i {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$tag-editor-svg-close-color-hover}' d='#{$tag-editor-svg-close}'/></svg>");
      }
    }
  }
}

.tag-editor-hidden-src {
  position: absolute !important;
  left: -99999px;
}
