/* Iconic for Bootstrap */

@font-face {
  font-family: iconic-sm;
  src: url('#{$IconicPath}/iconic-sm.eot');
  src: url('#{$IconicPath}/iconic-sm.eot?#iconic-sm') format('embedded-opentype'), url('#{$IconicPath}/iconic-sm.woff') format('woff'), url('#{$IconicPath}/iconic-sm.ttf') format('truetype'), url('#{$IconicPath}/iconic-sm.svg#iconic-sm') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: iconic-md;
  src: url('#{$IconicPath}/iconic-md.eot');
  src: url('#{$IconicPath}/iconic-md.eot?#iconic-md') format('embedded-opentype'), url('#{$IconicPath}/iconic-md.woff') format('woff'), url('#{$IconicPath}/iconic-md.ttf') format('truetype'), url('#{$IconicPath}/iconic-md.svg#iconic-md') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: iconic-lg;
  src: url('#{$IconicPath}/iconic-lg.eot');
  src: url('#{$IconicPath}/iconic-lg.eot?#iconic-lg') format('embedded-opentype'), url('#{$IconicPath}/iconic-lg.woff') format('woff'), url('#{$IconicPath}/iconic-lg.ttf') format('truetype'), url('#{$IconicPath}/iconic-lg.svg#iconic-lg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Catchall baseclass */
.iconic {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: iconic-sm, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    display: inline-block;
  }

  &:empty::before {
    width: 1em;
    text-align: center;
    box-sizing: content-box;
  }

  &.iconic-sm::before,
  &.iconic-md::before,
  &.iconic-lg::before,
  &.iconic-icon-sm::before,
  &.iconic-icon-md::before,
  &.iconic-icon-lg::before {
    font-weight: normal;
    font-style: normal;
  }

  &.iconic-sm::before {
    font-family: iconic-sm, sans-serif;
    font-size: 16px;
  }

  &.iconic-md::before {
    font-family: iconic-md;
    font-size: 32px;
  }

  &.iconic-lg::before {
    font-family: iconic-lg;
    font-size: 128px;
  }


  &.iconic-icon-sm::before {
    font-family: iconic-sm, sans-serif;
  }

  &.iconic-icon-md::before {
    font-family: iconic-md;
  }

  &.iconic-icon-lg::before {
    font-family: iconic-lg;
  }


  &.iconic-size-sm::before {
    font-size: 16px;
  }

  &.iconic-size-md::before {
    font-size: 32px;
  }

  &.iconic-size-lg::before {
    font-size: 128px;
  }


  &.iconic-align-center::before {
    text-align: center;
  }

  &.iconic-align-left::before {
    text-align: left;
  }

  &.iconic-align-right::before {
    text-align: right;
  }


  &.iconic-flip-horizontal::before {
    transform: scale(-1, 1);
  }

  &.iconic-flip-vertical::before {
    transform: scale(1, -1);
  }

  &.iconic-flip-horizontal-vertical::before {
    transform: scale(-1, -1);
  }
}



.iconic-account-login::before {
  content:'\e000';
}

.iconic-account-logout::before {
  content:'\e001';
}

.iconic-action-redo::before {
  content:'\e002';
}

.iconic-action-undo::before {
  content:'\e003';
}

.iconic-align-center::before {
  content:'\e004';
}

.iconic-align-left::before {
  content:'\e005';
}

.iconic-align-right::before {
  content:'\e006';
}

.iconic-aperture::before {
  content:'\e007';
}

.iconic-arrow-bottom-fill-acute::before {
  content:'\e008';
}

.iconic-arrow-bottom-fill-angled::before {
  content:'\e009';
}

.iconic-arrow-bottom-fill-large-acute::before {
  content:'\e00a';
}

.iconic-arrow-bottom-fill-large-angled::before {
  content:'\e00b';
}

.iconic-arrow-bottom-fill-large-oblique::before {
  content:'\e00c';
}

.iconic-arrow-bottom-fill-large::before {
  content:'\e00d';
}

.iconic-arrow-bottom-fill-oblique::before {
  content:'\e00e';
}

.iconic-arrow-bottom-fill::before {
  content:'\e00f';
}

.iconic-arrow-bottom-left-fill-acute::before {
  content:'\e010';
}

.iconic-arrow-bottom-left-fill-angled::before {
  content:'\e011';
}

.iconic-arrow-bottom-left-fill-large-acute::before {
  content:'\e012';
}

.iconic-arrow-bottom-left-fill-large-angled::before {
  content:'\e013';
}

.iconic-arrow-bottom-left-fill-large-oblique::before {
  content:'\e014';
}

.iconic-arrow-bottom-left-fill-large::before {
  content:'\e015';
}

.iconic-arrow-bottom-left-fill-oblique::before {
  content:'\e016';
}

.iconic-arrow-bottom-left-fill::before {
  content:'\e017';
}

.iconic-arrow-bottom-left-line-acute::before {
  content:'\e018';
}

.iconic-arrow-bottom-left-line-large-acute::before {
  content:'\e019';
}

.iconic-arrow-bottom-left-line-large-oblique::before {
  content:'\e01a';
}

.iconic-arrow-bottom-left-line-large::before {
  content:'\e01b';
}

.iconic-arrow-bottom-left-line-oblique::before {
  content:'\e01c';
}

.iconic-arrow-bottom-left-line::before {
  content:'\e01d';
}

.iconic-arrow-bottom-line-acute::before {
  content:'\e01e';
}

.iconic-arrow-bottom-line-large-acute::before {
  content:'\e01f';
}

.iconic-arrow-bottom-line-large-oblique::before {
  content:'\e020';
}

.iconic-arrow-bottom-line-large::before {
  content:'\e021';
}

.iconic-arrow-bottom-line-oblique::before {
  content:'\e022';
}

.iconic-arrow-bottom-line::before {
  content:'\e023';
}

.iconic-arrow-bottom-right-fill-acute::before {
  content:'\e024';
}

.iconic-arrow-bottom-right-fill-angled::before {
  content:'\e025';
}

.iconic-arrow-bottom-right-fill-large-acute::before {
  content:'\e026';
}

.iconic-arrow-bottom-right-fill-large-angled::before {
  content:'\e027';
}

.iconic-arrow-bottom-right-fill-large-oblique::before {
  content:'\e028';
}

.iconic-arrow-bottom-right-fill-large::before {
  content:'\e029';
}

.iconic-arrow-bottom-right-fill-oblique::before {
  content:'\e02a';
}

.iconic-arrow-bottom-right-fill::before {
  content:'\e02b';
}

.iconic-arrow-bottom-right-line-acute::before {
  content:'\e02c';
}

.iconic-arrow-bottom-right-line-large-acute::before {
  content:'\e02d';
}

.iconic-arrow-bottom-right-line-large-oblique::before {
  content:'\e02e';
}

.iconic-arrow-bottom-right-line-large::before {
  content:'\e02f';
}

.iconic-arrow-bottom-right-line-oblique::before {
  content:'\e030';
}

.iconic-arrow-bottom-right-line::before {
  content:'\e031';
}

.iconic-arrow-left-fill-acute::before {
  content:'\e032';
}

.iconic-arrow-left-fill-angled::before {
  content:'\e033';
}

.iconic-arrow-left-fill-large-acute::before {
  content:'\e034';
}

.iconic-arrow-left-fill-large-angled::before {
  content:'\e035';
}

.iconic-arrow-left-fill-large-oblique::before {
  content:'\e036';
}

.iconic-arrow-left-fill-large::before {
  content:'\e037';
}

.iconic-arrow-left-fill-oblique::before {
  content:'\e038';
}

.iconic-arrow-left-fill::before {
  content:'\e039';
}

.iconic-arrow-left-line-acute::before {
  content:'\e03a';
}

.iconic-arrow-left-line-large-acute::before {
  content:'\e03b';
}

.iconic-arrow-left-line-large-oblique::before {
  content:'\e03c';
}

.iconic-arrow-left-line-large::before {
  content:'\e03d';
}

.iconic-arrow-left-line-oblique::before {
  content:'\e03e';
}

.iconic-arrow-left-line::before {
  content:'\e03f';
}

.iconic-arrow-right-angle-bottom-left-fill-acute::before {
  content:'\e040';
}

.iconic-arrow-right-angle-bottom-left-fill-angled::before {
  content:'\e041';
}

.iconic-arrow-right-angle-bottom-left-fill::before {
  content:'\e042';
}

.iconic-arrow-right-angle-bottom-left-line::before {
  content:'\e043';
}

.iconic-arrow-right-angle-bottom-right-fill-acute::before {
  content:'\e044';
}

.iconic-arrow-right-angle-bottom-right-fill-angled::before {
  content:'\e045';
}

.iconic-arrow-right-angle-bottom-right-fill::before {
  content:'\e046';
}

.iconic-arrow-right-angle-bottom-right-line::before {
  content:'\e047';
}

.iconic-arrow-right-angle-left-bottom-fill-acute::before {
  content:'\e048';
}

.iconic-arrow-right-angle-left-bottom-fill-angled::before {
  content:'\e049';
}

.iconic-arrow-right-angle-left-bottom-fill::before {
  content:'\e04a';
}

.iconic-arrow-right-angle-left-bottom-line::before {
  content:'\e04b';
}

.iconic-arrow-right-angle-left-top-fill-acute::before {
  content:'\e04c';
}

.iconic-arrow-right-angle-left-top-fill-angled::before {
  content:'\e04d';
}

.iconic-arrow-right-angle-left-top-fill::before {
  content:'\e04e';
}

.iconic-arrow-right-angle-left-top-line::before {
  content:'\e04f';
}

.iconic-arrow-right-angle-right-bottom-fill-acute::before {
  content:'\e050';
}

.iconic-arrow-right-angle-right-bottom-fill-angled::before {
  content:'\e051';
}

.iconic-arrow-right-angle-right-bottom-fill::before {
  content:'\e052';
}

.iconic-arrow-right-angle-right-bottom-line::before {
  content:'\e053';
}

.iconic-arrow-right-angle-right-top-fill-acute::before {
  content:'\e054';
}

.iconic-arrow-right-angle-right-top-fill-angled::before {
  content:'\e055';
}

.iconic-arrow-right-angle-right-top-fill::before {
  content:'\e056';
}

.iconic-arrow-right-angle-right-top-line::before {
  content:'\e057';
}

.iconic-arrow-right-angle-thick-bottom-left-fill-acute::before {
  content:'\e058';
}

.iconic-arrow-right-angle-thick-bottom-left-fill-angled::before {
  content:'\e059';
}

.iconic-arrow-right-angle-thick-bottom-left-fill::before {
  content:'\e05a';
}

.iconic-arrow-right-angle-thick-bottom-left-line::before {
  content:'\e05b';
}

.iconic-arrow-right-angle-thick-bottom-right-fill-acute::before {
  content:'\e05c';
}

.iconic-arrow-right-angle-thick-bottom-right-fill-angled::before {
  content:'\e05d';
}

.iconic-arrow-right-angle-thick-bottom-right-fill::before {
  content:'\e05e';
}

.iconic-arrow-right-angle-thick-bottom-right-line::before {
  content:'\e05f';
}

.iconic-arrow-right-angle-thick-left-bottom-fill-acute::before {
  content:'\e060';
}

.iconic-arrow-right-angle-thick-left-bottom-fill-angled::before {
  content:'\e061';
}

.iconic-arrow-right-angle-thick-left-bottom-fill::before {
  content:'\e062';
}

.iconic-arrow-right-angle-thick-left-bottom-line::before {
  content:'\e063';
}

.iconic-arrow-right-angle-thick-left-top-fill-acute::before {
  content:'\e064';
}

.iconic-arrow-right-angle-thick-left-top-fill-angled::before {
  content:'\e065';
}

.iconic-arrow-right-angle-thick-left-top-fill::before {
  content:'\e066';
}

.iconic-arrow-right-angle-thick-left-top-line::before {
  content:'\e067';
}

.iconic-arrow-right-angle-thick-right-bottom-fill-acute::before {
  content:'\e068';
}

.iconic-arrow-right-angle-thick-right-bottom-fill-angled::before {
  content:'\e069';
}

.iconic-arrow-right-angle-thick-right-bottom-fill::before {
  content:'\e06a';
}

.iconic-arrow-right-angle-thick-right-bottom-line::before {
  content:'\e06b';
}

.iconic-arrow-right-angle-thick-right-top-fill-acute::before {
  content:'\e06c';
}

.iconic-arrow-right-angle-thick-right-top-fill-angled::before {
  content:'\e06d';
}

.iconic-arrow-right-angle-thick-right-top-fill::before {
  content:'\e06e';
}

.iconic-arrow-right-angle-thick-right-top-line::before {
  content:'\e06f';
}

.iconic-arrow-right-angle-thick-top-left-fill-acute::before {
  content:'\e070';
}

.iconic-arrow-right-angle-thick-top-left-fill-angled::before {
  content:'\e071';
}

.iconic-arrow-right-angle-thick-top-left-fill::before {
  content:'\e072';
}

.iconic-arrow-right-angle-thick-top-left-line::before {
  content:'\e073';
}

.iconic-arrow-right-angle-thick-top-right-fill-acute::before {
  content:'\e074';
}

.iconic-arrow-right-angle-thick-top-right-fill-angled::before {
  content:'\e075';
}

.iconic-arrow-right-angle-thick-top-right-fill::before {
  content:'\e076';
}

.iconic-arrow-right-angle-thick-top-right-line::before {
  content:'\e077';
}

.iconic-arrow-right-angle-top-left-fill-acute::before {
  content:'\e078';
}

.iconic-arrow-right-angle-top-left-fill-angled::before {
  content:'\e079';
}

.iconic-arrow-right-angle-top-left-fill::before {
  content:'\e07a';
}

.iconic-arrow-right-angle-top-left-line::before {
  content:'\e07b';
}

.iconic-arrow-right-angle-top-right-fill-acute::before {
  content:'\e07c';
}

.iconic-arrow-right-angle-top-right-fill-angled::before {
  content:'\e07d';
}

.iconic-arrow-right-angle-top-right-fill::before {
  content:'\e07e';
}

.iconic-arrow-right-angle-top-right-line::before {
  content:'\e07f';
}

.iconic-arrow-right-fill-acute::before {
  content:'\e080';
}

.iconic-arrow-right-fill-angled::before {
  content:'\e081';
}

.iconic-arrow-right-fill-large-acute::before {
  content:'\e082';
}

.iconic-arrow-right-fill-large-angled::before {
  content:'\e083';
}

.iconic-arrow-right-fill-large-oblique::before {
  content:'\e084';
}

.iconic-arrow-right-fill-large::before {
  content:'\e085';
}

.iconic-arrow-right-fill-oblique::before {
  content:'\e086';
}

.iconic-arrow-right-fill::before {
  content:'\e087';
}

.iconic-arrow-right-line-acute::before {
  content:'\e088';
}

.iconic-arrow-right-line-large-acute::before {
  content:'\e089';
}

.iconic-arrow-right-line-large-oblique::before {
  content:'\e08a';
}

.iconic-arrow-right-line-large::before {
  content:'\e08b';
}

.iconic-arrow-right-line-oblique::before {
  content:'\e08c';
}

.iconic-arrow-right-line::before {
  content:'\e08d';
}

.iconic-arrow-thick-bottom-fill-acute::before {
  content:'\e08e';
}

.iconic-arrow-thick-bottom-fill-angled::before {
  content:'\e08f';
}

.iconic-arrow-thick-bottom-fill-large-acute::before {
  content:'\e090';
}

.iconic-arrow-thick-bottom-fill-large-angled::before {
  content:'\e091';
}

.iconic-arrow-thick-bottom-fill-large-oblique::before {
  content:'\e092';
}

.iconic-arrow-thick-bottom-fill-large::before {
  content:'\e093';
}

.iconic-arrow-thick-bottom-fill-oblique::before {
  content:'\e094';
}

.iconic-arrow-thick-bottom-fill::before {
  content:'\e095';
}

.iconic-arrow-thick-bottom-left-fill-acute::before {
  content:'\e096';
}

.iconic-arrow-thick-bottom-left-fill-angled::before {
  content:'\e097';
}

.iconic-arrow-thick-bottom-left-fill-large-acute::before {
  content:'\e098';
}

.iconic-arrow-thick-bottom-left-fill-large-angled::before {
  content:'\e099';
}

.iconic-arrow-thick-bottom-left-fill-large-oblique::before {
  content:'\e09a';
}

.iconic-arrow-thick-bottom-left-fill-large::before {
  content:'\e09b';
}

.iconic-arrow-thick-bottom-left-fill-oblique::before {
  content:'\e09c';
}

.iconic-arrow-thick-bottom-left-fill::before {
  content:'\e09d';
}

.iconic-arrow-thick-bottom-left-line-acute::before {
  content:'\e09e';
}

.iconic-arrow-thick-bottom-left-line-large-acute::before {
  content:'\e09f';
}

.iconic-arrow-thick-bottom-left-line-large-oblique::before {
  content:'\e0a0';
}

.iconic-arrow-thick-bottom-left-line-large::before {
  content:'\e0a1';
}

.iconic-arrow-thick-bottom-left-line-oblique::before {
  content:'\e0a2';
}

.iconic-arrow-thick-bottom-left-line::before {
  content:'\e0a3';
}

.iconic-arrow-thick-bottom-line-acute::before {
  content:'\e0a4';
}

.iconic-arrow-thick-bottom-line-large-acute::before {
  content:'\e0a5';
}

.iconic-arrow-thick-bottom-line-large-oblique::before {
  content:'\e0a6';
}

.iconic-arrow-thick-bottom-line-large::before {
  content:'\e0a7';
}

.iconic-arrow-thick-bottom-line-oblique::before {
  content:'\e0a8';
}

.iconic-arrow-thick-bottom-line::before {
  content:'\e0a9';
}

.iconic-arrow-thick-bottom-right-fill-acute::before {
  content:'\e0aa';
}

.iconic-arrow-thick-bottom-right-fill-angled::before {
  content:'\e0ab';
}

.iconic-arrow-thick-bottom-right-fill-large-acute::before {
  content:'\e0ac';
}

.iconic-arrow-thick-bottom-right-fill-large-angled::before {
  content:'\e0ad';
}

.iconic-arrow-thick-bottom-right-fill-large-oblique::before {
  content:'\e0ae';
}

.iconic-arrow-thick-bottom-right-fill-large::before {
  content:'\e0af';
}

.iconic-arrow-thick-bottom-right-fill-oblique::before {
  content:'\e0b0';
}

.iconic-arrow-thick-bottom-right-fill::before {
  content:'\e0b1';
}

.iconic-arrow-thick-bottom-right-line-acute::before {
  content:'\e0b2';
}

.iconic-arrow-thick-bottom-right-line-large-acute::before {
  content:'\e0b3';
}

.iconic-arrow-thick-bottom-right-line-large-oblique::before {
  content:'\e0b4';
}

.iconic-arrow-thick-bottom-right-line-large::before {
  content:'\e0b5';
}

.iconic-arrow-thick-bottom-right-line-oblique::before {
  content:'\e0b6';
}

.iconic-arrow-thick-bottom-right-line::before {
  content:'\e0b7';
}

.iconic-arrow-thick-left-fill-acute::before {
  content:'\e0b8';
}

.iconic-arrow-thick-left-fill-angled::before {
  content:'\e0b9';
}

.iconic-arrow-thick-left-fill-large-acute::before {
  content:'\e0ba';
}

.iconic-arrow-thick-left-fill-large-angled::before {
  content:'\e0bb';
}

.iconic-arrow-thick-left-fill-large-oblique::before {
  content:'\e0bc';
}

.iconic-arrow-thick-left-fill-large::before {
  content:'\e0bd';
}

.iconic-arrow-thick-left-fill-oblique::before {
  content:'\e0be';
}

.iconic-arrow-thick-left-fill::before {
  content:'\e0bf';
}

.iconic-arrow-thick-left-line-acute::before {
  content:'\e0c0';
}

.iconic-arrow-thick-left-line-large-acute::before {
  content:'\e0c1';
}

.iconic-arrow-thick-left-line-large-oblique::before {
  content:'\e0c2';
}

.iconic-arrow-thick-left-line-large::before {
  content:'\e0c3';
}

.iconic-arrow-thick-left-line-oblique::before {
  content:'\e0c4';
}

.iconic-arrow-thick-left-line::before {
  content:'\e0c5';
}

.iconic-arrow-thick-right-fill-acute::before {
  content:'\e0c6';
}

.iconic-arrow-thick-right-fill-angled::before {
  content:'\e0c7';
}

.iconic-arrow-thick-right-fill-large-acute::before {
  content:'\e0c8';
}

.iconic-arrow-thick-right-fill-large-angled::before {
  content:'\e0c9';
}

.iconic-arrow-thick-right-fill-large-oblique::before {
  content:'\e0ca';
}

.iconic-arrow-thick-right-fill-large::before {
  content:'\e0cb';
}

.iconic-arrow-thick-right-fill-oblique::before {
  content:'\e0cc';
}

.iconic-arrow-thick-right-fill::before {
  content:'\e0cd';
}

.iconic-arrow-thick-right-line-acute::before {
  content:'\e0ce';
}

.iconic-arrow-thick-right-line-large-acute::before {
  content:'\e0cf';
}

.iconic-arrow-thick-right-line-large-oblique::before {
  content:'\e0d0';
}

.iconic-arrow-thick-right-line-large::before {
  content:'\e0d1';
}

.iconic-arrow-thick-right-line-oblique::before {
  content:'\e0d2';
}

.iconic-arrow-thick-right-line::before {
  content:'\e0d3';
}

.iconic-arrow-thick-top-fill-acute::before {
  content:'\e0d4';
}

.iconic-arrow-thick-top-fill-angled::before {
  content:'\e0d5';
}

.iconic-arrow-thick-top-fill-large-acute::before {
  content:'\e0d6';
}

.iconic-arrow-thick-top-fill-large-angled::before {
  content:'\e0d7';
}

.iconic-arrow-thick-top-fill-large-oblique::before {
  content:'\e0d8';
}

.iconic-arrow-thick-top-fill-large::before {
  content:'\e0d9';
}

.iconic-arrow-thick-top-fill-oblique::before {
  content:'\e0da';
}

.iconic-arrow-thick-top-fill::before {
  content:'\e0db';
}

.iconic-arrow-thick-top-left-fill-acute::before {
  content:'\e0dc';
}

.iconic-arrow-thick-top-left-fill-angled::before {
  content:'\e0dd';
}

.iconic-arrow-thick-top-left-fill-large-acute::before {
  content:'\e0de';
}

.iconic-arrow-thick-top-left-fill-large-angled::before {
  content:'\e0df';
}

.iconic-arrow-thick-top-left-fill-large-oblique::before {
  content:'\e0e0';
}

.iconic-arrow-thick-top-left-fill-large::before {
  content:'\e0e1';
}

.iconic-arrow-thick-top-left-fill-oblique::before {
  content:'\e0e2';
}

.iconic-arrow-thick-top-left-fill::before {
  content:'\e0e3';
}

.iconic-arrow-thick-top-left-line-acute::before {
  content:'\e0e4';
}

.iconic-arrow-thick-top-left-line-large-acute::before {
  content:'\e0e5';
}

.iconic-arrow-thick-top-left-line-large-oblique::before {
  content:'\e0e6';
}

.iconic-arrow-thick-top-left-line-large::before {
  content:'\e0e7';
}

.iconic-arrow-thick-top-left-line-oblique::before {
  content:'\e0e8';
}

.iconic-arrow-thick-top-left-line::before {
  content:'\e0e9';
}

.iconic-arrow-thick-top-line-acute::before {
  content:'\e0ea';
}

.iconic-arrow-thick-top-line-large-acute::before {
  content:'\e0eb';
}

.iconic-arrow-thick-top-line-large-oblique::before {
  content:'\e0ec';
}

.iconic-arrow-thick-top-line-large::before {
  content:'\e0ed';
}

.iconic-arrow-thick-top-line-oblique::before {
  content:'\e0ee';
}

.iconic-arrow-thick-top-line::before {
  content:'\e0ef';
}

.iconic-arrow-thick-top-right-fill-acute::before {
  content:'\e0f0';
}

.iconic-arrow-thick-top-right-fill-angled::before {
  content:'\e0f1';
}

.iconic-arrow-thick-top-right-fill-large-acute::before {
  content:'\e0f2';
}

.iconic-arrow-thick-top-right-fill-large-angled::before {
  content:'\e0f3';
}

.iconic-arrow-thick-top-right-fill-large-oblique::before {
  content:'\e0f4';
}

.iconic-arrow-thick-top-right-fill-large::before {
  content:'\e0f5';
}

.iconic-arrow-thick-top-right-fill-oblique::before {
  content:'\e0f6';
}

.iconic-arrow-thick-top-right-fill::before {
  content:'\e0f7';
}

.iconic-arrow-thick-top-right-line-acute::before {
  content:'\e0f8';
}

.iconic-arrow-thick-top-right-line-large-acute::before {
  content:'\e0f9';
}

.iconic-arrow-thick-top-right-line-large-oblique::before {
  content:'\e0fa';
}

.iconic-arrow-thick-top-right-line-large::before {
  content:'\e0fb';
}

.iconic-arrow-thick-top-right-line-oblique::before {
  content:'\e0fc';
}

.iconic-arrow-thick-top-right-line::before {
  content:'\e0fd';
}

.iconic-arrow-top-fill-acute::before {
  content:'\e0fe';
}

.iconic-arrow-top-fill-angled::before {
  content:'\e0ff';
}

.iconic-arrow-top-fill-large-acute::before {
  content:'\e100';
}

.iconic-arrow-top-fill-large-angled::before {
  content:'\e101';
}

.iconic-arrow-top-fill-large-oblique::before {
  content:'\e102';
}

.iconic-arrow-top-fill-large::before {
  content:'\e103';
}

.iconic-arrow-top-fill-oblique::before {
  content:'\e104';
}

.iconic-arrow-top-fill::before {
  content:'\e105';
}

.iconic-arrow-top-left-fill-acute::before {
  content:'\e106';
}

.iconic-arrow-top-left-fill-angled::before {
  content:'\e107';
}

.iconic-arrow-top-left-fill-large-acute::before {
  content:'\e108';
}

.iconic-arrow-top-left-fill-large-angled::before {
  content:'\e109';
}

.iconic-arrow-top-left-fill-large-oblique::before {
  content:'\e10a';
}

.iconic-arrow-top-left-fill-large::before {
  content:'\e10b';
}

.iconic-arrow-top-left-fill-oblique::before {
  content:'\e10c';
}

.iconic-arrow-top-left-fill::before {
  content:'\e10d';
}

.iconic-arrow-top-left-line-acute::before {
  content:'\e10e';
}

.iconic-arrow-top-left-line-large-acute::before {
  content:'\e10f';
}

.iconic-arrow-top-left-line-large-oblique::before {
  content:'\e110';
}

.iconic-arrow-top-left-line-large::before {
  content:'\e111';
}

.iconic-arrow-top-left-line-oblique::before {
  content:'\e112';
}

.iconic-arrow-top-left-line::before {
  content:'\e113';
}

.iconic-arrow-top-line-acute::before {
  content:'\e114';
}

.iconic-arrow-top-line-large-acute::before {
  content:'\e115';
}

.iconic-arrow-top-line-large-oblique::before {
  content:'\e116';
}

.iconic-arrow-top-line-large::before {
  content:'\e117';
}

.iconic-arrow-top-line-oblique::before {
  content:'\e118';
}

.iconic-arrow-top-line::before {
  content:'\e119';
}

.iconic-arrow-top-right-fill-acute::before {
  content:'\e11a';
}

.iconic-arrow-top-right-fill-angled::before {
  content:'\e11b';
}

.iconic-arrow-top-right-fill-large-acute::before {
  content:'\e11c';
}

.iconic-arrow-top-right-fill-large-angled::before {
  content:'\e11d';
}

.iconic-arrow-top-right-fill-large-oblique::before {
  content:'\e11e';
}

.iconic-arrow-top-right-fill-large::before {
  content:'\e11f';
}

.iconic-arrow-top-right-fill-oblique::before {
  content:'\e120';
}

.iconic-arrow-top-right-fill::before {
  content:'\e121';
}

.iconic-arrow-top-right-line-acute::before {
  content:'\e122';
}

.iconic-arrow-top-right-line-large-acute::before {
  content:'\e123';
}

.iconic-arrow-top-right-line-large-oblique::before {
  content:'\e124';
}

.iconic-arrow-top-right-line-large::before {
  content:'\e125';
}

.iconic-arrow-top-right-line-oblique::before {
  content:'\e126';
}

.iconic-arrow-top-right-line::before {
  content:'\e127';
}

.iconic-audio-spectrum::before {
  content:'\e128';
}

.iconic-audio::before {
  content:'\e129';
}

.iconic-ban::before {
  content:'\e12a';
}

.iconic-bar-chart::before {
  content:'\e12b';
}

.iconic-barcode::before {
  content:'\e12c';
}

.iconic-basket::before {
  content:'\e12d';
}

.iconic-battery::before {
  content:'\e12e';
}

.iconic-beaker::before {
  content:'\e12f';
}

.iconic-bell::before {
  content:'\e130';
}

.iconic-bitcoin-address::before {
  content:'\e131';
}

.iconic-bitcoin-block::before {
  content:'\e132';
}

.iconic-bitcoin-transaction::before {
  content:'\e133';
}

.iconic-bitcoin::before {
  content:'\e134';
}

.iconic-bluetooth::before {
  content:'\e135';
}

.iconic-bold::before {
  content:'\e136';
}

.iconic-book::before {
  content:'\e137';
}

.iconic-bookmark::before {
  content:'\e138';
}

.iconic-box::before {
  content:'\e139';
}

.iconic-brain::before {
  content:'\e13a';
}

.iconic-briefcase::before {
  content:'\e13b';
}

.iconic-british-pound::before {
  content:'\e13c';
}

.iconic-browser-full-page::before {
  content:'\e13d';
}

.iconic-browser-type-chrome::before {
  content:'\e13e';
}

.iconic-browser-type-firefox::before {
  content:'\e13f';
}

.iconic-browser-type-internetexplorer::before {
  content:'\e140';
}

.iconic-browser-type-safari::before {
  content:'\e141';
}

.iconic-browser-viewport::before {
  content:'\e142';
}

.iconic-browser::before {
  content:'\e143';
}

.iconic-brush::before {
  content:'\e144';
}

.iconic-bug::before {
  content:'\e145';
}

.iconic-building::before {
  content:'\e146';
}

.iconic-bullhorn::before {
  content:'\e147';
}

.iconic-calculator::before {
  content:'\e148';
}

.iconic-calendar::before {
  content:'\e149';
}

.iconic-camera-rangefinder::before {
  content:'\e14a';
}

.iconic-camera-slr::before {
  content:'\e14b';
}

.iconic-caret-bottom::before {
  content:'\e14c';
}

.iconic-caret-left::before {
  content:'\e14d';
}

.iconic-caret-right::before {
  content:'\e14e';
}

.iconic-caret-top::before {
  content:'\e14f';
}

.iconic-carriage-return::before {
  content:'\e150';
}

.iconic-cart::before {
  content:'\e151';
}

.iconic-chat::before {
  content:'\e152';
}

.iconic-check-thin::before {
  content:'\e153';
}

.iconic-check::before {
  content:'\e154';
}

.iconic-chevron-bottom::before {
  content:'\e155';
}

.iconic-chevron-left::before {
  content:'\e156';
}

.iconic-chevron-right::before {
  content:'\e157';
}

.iconic-chevron-top::before {
  content:'\e158';
}

.iconic-circle-check::before {
  content:'\e159';
}

.iconic-circle-x::before {
  content:'\e15a';
}

.iconic-clipboard::before {
  content:'\e15b';
}

.iconic-clock::before {
  content:'\e15c';
}

.iconic-cloud-transfer-download::before {
  content:'\e15d';
}

.iconic-cloud-transfer-upload::before {
  content:'\e15e';
}

.iconic-cloud::before {
  content:'\e15f';
}

.iconic-cloudy::before {
  content:'\e160';
}

.iconic-code::before {
  content:'\e161';
}

.iconic-cog::before {
  content:'\e162';
}

.iconic-cogs::before {
  content:'\e163';
}

.iconic-collapse-down::before {
  content:'\e164';
}

.iconic-collapse-left::before {
  content:'\e165';
}

.iconic-collapse-right::before {
  content:'\e166';
}

.iconic-collapse-up::before {
  content:'\e167';
}

.iconic-command::before {
  content:'\e168';
}

.iconic-comment-square::before {
  content:'\e169';
}

.iconic-compass::before {
  content:'\e16a';
}

.iconic-connections::before {
  content:'\e16b';
}

.iconic-contrast::before {
  content:'\e16c';
}

.iconic-copyleft::before {
  content:'\e16d';
}

.iconic-copyright::before {
  content:'\e16e';
}

.iconic-copywriting::before {
  content:'\e16f';
}

.iconic-cpu::before {
  content:'\e170';
}

.iconic-credit-card::before {
  content:'\e171';
}

.iconic-crop::before {
  content:'\e172';
}

.iconic-dashboard::before {
  content:'\e173';
}

.iconic-data-transfer-download::before {
  content:'\e174';
}

.iconic-data-transfer-upload::before {
  content:'\e175';
}

.iconic-database::before {
  content:'\e176';
}

.iconic-delete::before {
  content:'\e177';
}

.iconic-delta::before {
  content:'\e178';
}

.iconic-dial::before {
  content:'\e179';
}

.iconic-dna::before {
  content:'\e17a';
}

.iconic-document::before {
  content:'\e17b';
}

.iconic-dollar::before {
  content:'\e17c';
}

.iconic-double-quote-sans-left::before {
  content:'\e17d';
}

.iconic-double-quote-sans-right::before {
  content:'\e17e';
}

.iconic-double-quote-serif-left::before {
  content:'\e17f';
}

.iconic-double-quote-serif-right::before {
  content:'\e180';
}

.iconic-easel::before {
  content:'\e181';
}

.iconic-eject::before {
  content:'\e182';
}

.iconic-electric::before {
  content:'\e183';
}

.iconic-elevator::before {
  content:'\e184';
}

.iconic-ellipses::before {
  content:'\e185';
}

.iconic-envelope-closed::before {
  content:'\e186';
}

.iconic-envelope-open::before {
  content:'\e187';
}

.iconic-euro::before {
  content:'\e188';
}

.iconic-excerpt::before {
  content:'\e189';
}

.iconic-expand-down::before {
  content:'\e18a';
}

.iconic-expand-left::before {
  content:'\e18b';
}

.iconic-expand-right::before {
  content:'\e18c';
}

.iconic-expand-up::before {
  content:'\e18d';
}

.iconic-external-link::before {
  content:'\e18e';
}

.iconic-eye-closed::before {
  content:'\e18f';
}

.iconic-eye-open::before {
  content:'\e190';
}

.iconic-eyedropper::before {
  content:'\e191';
}

.iconic-file-ai::before {
  content:'\e192';
}

.iconic-file-css::before {
  content:'\e193';
}

.iconic-file-dmg::before {
  content:'\e194';
}

.iconic-file-doc::before {
  content:'\e195';
}

.iconic-file-gif::before {
  content:'\e196';
}

.iconic-file-html::before {
  content:'\e197';
}

.iconic-file-jpg::before {
  content:'\e198';
}

.iconic-file-js::before {
  content:'\e199';
}

.iconic-file-json::before {
  content:'\e19a';
}

.iconic-file-mov::before {
  content:'\e19b';
}

.iconic-file-mp3::before {
  content:'\e19c';
}

.iconic-file-pdf::before {
  content:'\e19d';
}

.iconic-file-png::before {
  content:'\e19e';
}

.iconic-file-psd::before {
  content:'\e19f';
}

.iconic-file-svg::before {
  content:'\e1a0';
}

.iconic-file-txt::before {
  content:'\e1a1';
}

.iconic-file-xls::before {
  content:'\e1a2';
}

.iconic-file-xml::before {
  content:'\e1a3';
}

.iconic-file-zip::before {
  content:'\e1a4';
}

.iconic-fire::before {
  content:'\e1a5';
}

.iconic-firefly::before {
  content:'\e1a6';
}

.iconic-flag::before {
  content:'\e1a7';
}

.iconic-flash::before {
  content:'\e1a8';
}

.iconic-flow-four-up::before {
  content:'\e1a9';
}

.iconic-flow-three-up::before {
  content:'\e1aa';
}

.iconic-flow-two-up::before {
  content:'\e1ab';
}

.iconic-folder::before {
  content:'\e1ac';
}

.iconic-fork::before {
  content:'\e1ad';
}

.iconic-full-page::before {
  content:'\e1ae';
}

.iconic-fullscreen-enter::before {
  content:'\e1af';
}

.iconic-fullscreen-exit::before {
  content:'\e1b0';
}

.iconic-funnel::before {
  content:'\e1b1';
}

.iconic-game-controller::before {
  content:'\e1b2';
}

.iconic-globe::before {
  content:'\e1b3';
}

.iconic-graph::before {
  content:'\e1b4';
}

.iconic-grid-four-up::before {
  content:'\e1b5';
}

.iconic-grid-three-up::before {
  content:'\e1b6';
}

.iconic-grid-two-up::before {
  content:'\e1b7';
}

.iconic-group::before {
  content:'\e1b8';
}

.iconic-guides::before {
  content:'\e1b9';
}

.iconic-hammer::before {
  content:'\e1ba';
}

.iconic-hand::before {
  content:'\e1bb';
}

.iconic-hard-drive::before {
  content:'\e1bc';
}

.iconic-header::before {
  content:'\e1bd';
}

.iconic-headphones::before {
  content:'\e1be';
}

.iconic-heart::before {
  content:'\e1bf';
}

.iconic-history::before {
  content:'\e1c0';
}

.iconic-home::before {
  content:'\e1c1';
}

.iconic-image-landscape::before {
  content:'\e1c2';
}

.iconic-image-portrait::before {
  content:'\e1c3';
}

.iconic-inbox::before {
  content:'\e1c4';
}

.iconic-infinity::before {
  content:'\e1c5';
}

.iconic-info::before {
  content:'\e1c6';
}

.iconic-inkwell::before {
  content:'\e1c7';
}

.iconic-iphone::before {
  content:'\e1c8';
}

.iconic-italic::before {
  content:'\e1c9';
}

.iconic-justify-center::before {
  content:'\e1ca';
}

.iconic-justify-left::before {
  content:'\e1cb';
}

.iconic-justify-right::before {
  content:'\e1cc';
}

.iconic-key::before {
  content:'\e1cd';
}

.iconic-keyboard::before {
  content:'\e1ce';
}

.iconic-keypad-mobile::before {
  content:'\e1cf';
}

.iconic-laptop::before {
  content:'\e1d0';
}

.iconic-layers::before {
  content:'\e1d1';
}

.iconic-lightbulb-alt-off::before {
  content:'\e1d2';
}

.iconic-lightbulb-alt-on::before {
  content:'\e1d3';
}

.iconic-lightbulb::before {
  content:'\e1d4';
}

.iconic-lightning-bolt::before {
  content:'\e1d5';
}

.iconic-lightning::before {
  content:'\e1d6';
}

.iconic-link-broken::before {
  content:'\e1d7';
}

.iconic-link-intact::before {
  content:'\e1d8';
}

.iconic-list-nested::before {
  content:'\e1d9';
}

.iconic-list-rich::before {
  content:'\e1da';
}

.iconic-list::before {
  content:'\e1db';
}

.iconic-location::before {
  content:'\e1dc';
}

.iconic-lock-locked::before {
  content:'\e1dd';
}

.iconic-lock-unlocked::before {
  content:'\e1de';
}

.iconic-loop-circular::before {
  content:'\e1df';
}

.iconic-loop-square::before {
  content:'\e1e0';
}

.iconic-loop::before {
  content:'\e1e1';
}

.iconic-loupe::before {
  content:'\e1e2';
}

.iconic-magic-wand::before {
  content:'\e1e3';
}

.iconic-magnifying-glass::before {
  content:'\e1e4';
}

.iconic-map-marker::before {
  content:'\e1e5';
}

.iconic-map::before {
  content:'\e1e6';
}

.iconic-marquee::before {
  content:'\e1e7';
}

.iconic-medal::before {
  content:'\e1e8';
}

.iconic-media-pause::before {
  content:'\e1e9';
}

.iconic-media-play-circle::before {
  content:'\e1ea';
}

.iconic-media-play::before {
  content:'\e1eb';
}

.iconic-media-record::before {
  content:'\e1ec';
}

.iconic-media-skip-backward::before {
  content:'\e1ed';
}

.iconic-media-skip-forward::before {
  content:'\e1ee';
}

.iconic-media-step-backward::before {
  content:'\e1ef';
}

.iconic-media-step-forward::before {
  content:'\e1f0';
}

.iconic-media-stop::before {
  content:'\e1f1';
}

.iconic-medical-cross::before {
  content:'\e1f2';
}

.iconic-menu-selected::before {
  content:'\e1f3';
}

.iconic-menu::before {
  content:'\e1f4';
}

.iconic-microphone::before {
  content:'\e1f5';
}

.iconic-minus-thin::before {
  content:'\e1f6';
}

.iconic-minus::before {
  content:'\e1f7';
}

.iconic-monitor::before {
  content:'\e1f8';
}

.iconic-moon::before {
  content:'\e1f9';
}

.iconic-move::before {
  content:'\e1fa';
}

.iconic-musical-note::before {
  content:'\e1fb';
}

.iconic-network::before {
  content:'\e1fc';
}

.iconic-nexus::before {
  content:'\e1fd';
}

.iconic-open-hardware::before {
  content:'\e1fe';
}

.iconic-open-source::before {
  content:'\e1ff';
}

.iconic-palette::before {
  content:'\e200';
}

.iconic-paperclip::before {
  content:'\e201';
}

.iconic-peace::before {
  content:'\e202';
}

.iconic-pen::before {
  content:'\e203';
}

.iconic-pencil::before {
  content:'\e204';
}

.iconic-people::before {
  content:'\e205';
}

.iconic-person-female::before {
  content:'\e206';
}

.iconic-person-genderless::before {
  content:'\e207';
}

.iconic-person-male::before {
  content:'\e208';
}

.iconic-pie-chart::before {
  content:'\e209';
}

.iconic-pilcrow::before {
  content:'\e20a';
}

.iconic-pill::before {
  content:'\e20b';
}

.iconic-pin::before {
  content:'\e20c';
}

.iconic-platform-android::before {
  content:'\e20d';
}

.iconic-platform-apple::before {
  content:'\e20e';
}

.iconic-platform-linux::before {
  content:'\e20f';
}

.iconic-platform-microsoft::before {
  content:'\e210';
}

.iconic-plus-thin::before {
  content:'\e211';
}

.iconic-plus::before {
  content:'\e212';
}

.iconic-pointer::before {
  content:'\e213';
}

.iconic-power-standby::before {
  content:'\e214';
}

.iconic-print::before {
  content:'\e215';
}

.iconic-project::before {
  content:'\e216';
}

.iconic-pulse::before {
  content:'\e217';
}

.iconic-puzzle-piece::before {
  content:'\e218';
}

.iconic-question-mark::before {
  content:'\e219';
}

.iconic-radiation::before {
  content:'\e21a';
}

.iconic-rainy::before {
  content:'\e21b';
}

.iconic-random::before {
  content:'\e21c';
}

.iconic-ratio::before {
  content:'\e21d';
}

.iconic-reload::before {
  content:'\e21e';
}

.iconic-resize-both::before {
  content:'\e21f';
}

.iconic-resize-height::before {
  content:'\e220';
}

.iconic-resize-width::before {
  content:'\e221';
}

.iconic-rocket::before {
  content:'\e222';
}

.iconic-route::before {
  content:'\e223';
}

.iconic-rss-alt::before {
  content:'\e224';
}

.iconic-rss::before {
  content:'\e225';
}

.iconic-scissors::before {
  content:'\e226';
}

.iconic-screen-viewport::before {
  content:'\e227';
}

.iconic-screenshot::before {
  content:'\e228';
}

.iconic-screwdriver::before {
  content:'\e229';
}

.iconic-script::before {
  content:'\e22a';
}

.iconic-server::before {
  content:'\e22b';
}

.iconic-settings::before {
  content:'\e22c';
}

.iconic-shape-circle::before {
  content:'\e22d';
}

.iconic-shape-hexagon::before {
  content:'\e22e';
}

.iconic-shape-octagon::before {
  content:'\e22f';
}

.iconic-shape-square-rounded::before {
  content:'\e230';
}

.iconic-shape-square::before {
  content:'\e231';
}

.iconic-share-boxed::before {
  content:'\e232';
}

.iconic-share-social::before {
  content:'\e233';
}

.iconic-share::before {
  content:'\e234';
}

.iconic-shield::before {
  content:'\e235';
}

.iconic-signal::before {
  content:'\e236';
}

.iconic-signpost::before {
  content:'\e237';
}

.iconic-social-dribbble::before {
  content:'\e238';
}

.iconic-social-dropbox::before {
  content:'\e239';
}

.iconic-social-facebook::before {
  content:'\e23a';
}

.iconic-social-flickr::before {
  content:'\e23b';
}

.iconic-social-github::before {
  content:'\e23c';
}

.iconic-social-google-plus::before {
  content:'\e23d';
}

.iconic-social-instagram::before {
  content:'\e23e';
}

.iconic-social-linkedin::before {
  content:'\e23f';
}

.iconic-social-pinterest::before {
  content:'\e240';
}

.iconic-social-reddit::before {
  content:'\e241';
}

.iconic-social-tumblr::before {
  content:'\e242';
}

.iconic-social-twitter::before {
  content:'\e243';
}

.iconic-social-vimeo::before {
  content:'\e244';
}

.iconic-social-youtube::before {
  content:'\e245';
}

.iconic-sort-ascending::before {
  content:'\e246';
}

.iconic-sort-descending::before {
  content:'\e247';
}

.iconic-spreadsheet::before {
  content:'\e248';
}

.iconic-star-empty::before {
  content:'\e249';
}

.iconic-star::before {
  content:'\e24a';
}

.iconic-sun::before {
  content:'\e24b';
}

.iconic-syringe::before {
  content:'\e24c';
}

.iconic-tablet::before {
  content:'\e24d';
}

.iconic-tag::before {
  content:'\e24e';
}

.iconic-tags::before {
  content:'\e24f';
}

.iconic-target::before {
  content:'\e250';
}

.iconic-task::before {
  content:'\e251';
}

.iconic-terminal::before {
  content:'\e252';
}

.iconic-text::before {
  content:'\e253';
}

.iconic-thermometer::before {
  content:'\e254';
}

.iconic-thumb-down::before {
  content:'\e255';
}

.iconic-thumb-up::before {
  content:'\e256';
}

.iconic-tiara::before {
  content:'\e257';
}

.iconic-timer::before {
  content:'\e258';
}

.iconic-tint::before {
  content:'\e259';
}

.iconic-tools::before {
  content:'\e25a';
}

.iconic-transfer::before {
  content:'\e25b';
}

.iconic-trash::before {
  content:'\e25c';
}

.iconic-ungroup::before {
  content:'\e25d';
}

.iconic-vertical-align-bottom::before {
  content:'\e25e';
}

.iconic-vertical-align-center::before {
  content:'\e25f';
}

.iconic-vertical-align-top::before {
  content:'\e260';
}

.iconic-video::before {
  content:'\e261';
}

.iconic-viewport::before {
  content:'\e262';
}

.iconic-volume-high::before {
  content:'\e263';
}

.iconic-volume-low::before {
  content:'\e264';
}

.iconic-volume-medium::before {
  content:'\e265';
}

.iconic-volume-off::before {
  content:'\e266';
}

.iconic-warning::before {
  content:'\e267';
}

.iconic-weight::before {
  content:'\e268';
}

.iconic-wifi::before {
  content:'\e269';
}

.iconic-wrench::before {
  content:'\e26a';
}

.iconic-x-thin::before {
  content:'\e26b';
}

.iconic-x::before {
  content:'\e26c';
}

.iconic-yen::before {
  content:'\e26d';
}

.iconic-zoom-in::before {
  content:'\e26e';
}

.iconic-zoom-out::before {
  content:'\e26f';
}

